import axios from '@/app/plugins/Axios'

export default new class ListasService {

  perfis() {
    return axios.get('listas/perfis')
      .then(response => response.data);
  }

}
