import Login from '@/pages/Login'
import NaoEncontrado from '@/pages/404'

export default [
  { 
    path: '/404', 
    name: 'NaoEncontrado', 
    component: NaoEncontrado, 
    meta: { layout: 'publico', public: true } 
  },
  
  { 
    path: '/login', 
    name: 'Login', 
    component: Login, 
    meta: { layout: 'publico', public: true } 
  },

]
