<template>
  <!-- <div> -->
    <!-- <v-menu :disabled="disabled || readonly" ref="menu" left :close-on-content-click="false" v-model="menu" lazy transition="scale-transition" origin="center center" offset-y full-width max-width="290px" min-width="290px">
      <v-text-field slot="activator" :flat="isToBeFlat" :class="{'transparente': transparente && isToBeFlat}" ref="campo" v-bind="$attrs" v-model="dateFormatted" :readonly="readonly || isSm" :disabled="disabled" @input="onInput" @focus="onFocus" @blur="onBlur" @keyup="menu = false" @keypress.esc="dateFormatted = ''">
        <v-icon slot="prepend-inner" @click="menu = true" :disabled="disabled || readonly">event</v-icon>
      </v-text-field>
      <v-date-picker locale="pt-BR" v-model="date" no-title @input="menu = false" @change="onChange" :min="min"></v-date-picker>
    </v-menu> -->

    <v-text-field :flat="isToBeFlat" :class="{'transparente': transparente && isToBeFlat}" ref="campo" v-bind="$attrs" v-model="dateFormatted" :readonly="readonly || isSm" :disabled="disabled" @input="onInput" @focus="onFocus" @blur="onBlur" @keyup="menu = false" @keypress.esc="dateFormatted = ''">
      <v-menu v-show="!readonly && !disabled" slot="append" ref="menu" left :close-on-content-click="false" v-model="menu" lazy transition="scale-transition" origin="center center" offset-y full-width max-width="290px" min-width="290px">
        <v-icon slot="activator">event</v-icon>
        <v-date-picker locale="pt-BR" v-model="date" no-title @input="menu = false" @change="onChange" :min="min"></v-date-picker>
      </v-menu>
    </v-text-field>
  <!-- </div> -->
</template>

<script>
  export default {
    props: {
      value: {
        type: [String],
        default: null
      },
      transparente: {
        type: Boolean,
        default: false
      },
      raiseOnFocus: Boolean,
      flat: Boolean,
      readonly: Boolean,
      disabled: Boolean,
      min: String
    },

    data: (vm) => ({
      dateFormatted: null,
      menu: false,
      isFocused: false
    }),

    computed: {
      computedDateFormatted () {
        return this.dataBR(this.date)
      },

      date: {
        get () {
          this.dateFormatted = this.dataBR(this.value);
          return this.value;
        },
        set (val) {
          // this.dateFormatted = this.dataBR(val);
          this.$emit('input', val);
        }
      },

      isToBeFlat() {
        if (this.flat && this.raiseOnFocus && this.isFocused) {
          return false;
        } else {
          return this.flat;
        }
      },

      isSm() {
        return this.$vuetify.breakpoint.smAndDown;
      }
    },

    methods: {
      onInput() {
        if (this.dateFormatted) {
          if (this.dateFormatted.length == 2) {
              this.dateFormatted = this.dateFormatted + '/' + (new Date().getMonth() + 1).toString().padStart(2, '0');
              this.selecionarMes();
          } else if (this.dateFormatted.length == 5) {
              this.dateFormatted = this.dateFormatted + '/' + new Date().getFullYear();
              this.selecionarAno();
          }

          if (this.dateFormatted.length == 10) {
              this.$emit('input', this.dataUS(this.dateFormatted));
          }
        }
      },

      onFocus(e) {
        if (!this.isSm) {
          e.target.selectionStart = 0;
          e.target.selectionEnd   = e.target.value.length;
          this.isFocused = true;
        }
      },

      onBlur() {
        if (this.dateFormatted) {
          if (this.dateFormatted.length == 10) {
            this.date = this.dataUS(this.dateFormatted); 
          } else if (this.dateFormatted.length > 0 && this.dateFormatted.length < 10 && this.date != '') {
           this.dateFormatted = this.dataBR(this.date);
          } else {
            this.date = this.dateFormatted = '';
          }
        } else {
          this.date = this.dateFormatted;
        }

        this.isFocused = false;
      },

      onChange() {
        this.$emit('change', this.data);
      },

      selecionarAno() {
        setTimeout(() => {
          this.$refs.campo.$el.querySelector('input').selectionStart = 6;
          this.$refs.campo.$el.querySelector('input').selectionEnd   = 10;
        }, 0);
      },

      selecionarMes() {
        setTimeout(() => {
          this.$refs.campo.$el.querySelector('input').selectionStart = 3;
          this.$refs.campo.$el.querySelector('input').selectionEnd   = 5;
        }, 0);
      },

      dataBR (date) {
        if (!date) return null

        if (date.length > 10) {
          date = date.substr(0,10);
        }

        const [year, month, day] = date.split(/[-/]/);
        return `${day}/${month}/${year}`;
      },

      dataUS (date) {
        if (!date) return null

        const [year, month, day] = date.split(/[-/]/).reverse();
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      },
    }
  }
</script>

<style scoped>
  .transparente >>> .v-input__slot {
    background: none;
  }

  /* .borda >>> .v-input__slot {
    border: 1px solid silver;
    border-radius: 10px
  } */
</style>
