// import Auth from '@/app/plugins/Auth'
import createAuth from '@/app/plugins/AuthCore'

const auth = createAuth();

export function guards (to, from, next) {
  if (to.meta.public) {
      next();
  } else {
      if (auth.check()) {
        if (!to.meta.permissao) {
          next();
          return;
        }

        if (auth.temPermissao(to.meta.permissao))
          next();
        else 
          next('/');
      } else {
          next('/login');
      }
  }
}