import Vue from 'vue'

// Carregar Mixins e Plugins
import mixins from '@/app/mixins/mixins'
Vue.mixin(mixins);

// Carregar Plugins
// import AxiosCore from '@/app/plugins/AxiosCore'
import {axiosPlugin} from '@/app/plugins/Axios'
Vue.use(axiosPlugin);

// import {authPlugin} from '@/app/plugins/Auth'
import AuthCore from '@/app/plugins/AuthCore'
Vue.use(AuthCore);

import {cookiePlugin} from '@/app/plugins/Cookie'
Vue.use(cookiePlugin);

// Carregamento das constantes globais
import globals from '@/app/globals';
Vue.use(globals);

// Carregamento das configuraçãoes da Aplicação
import config from '@/app/config';
Vue.prototype.$config = config;

// Carregamento do sistema de notificações
import eventbus from '@/app/eventbus';
Vue.prototype.$eventbus = eventbus;

// Carregamento da Lib Moment
// import moment from 'moment';
// Vue.prototype.$moment = moment;

// Carregamento global dos zComponents
import zComponents from '@/components/zComponents';
Vue.use(zComponents);

// CKEditor
import CKEditor from '@ckeditor/ckeditor5-vue';
Vue.use(CKEditor);

// Carregamento dos estilos personalizados
import '@/assets/estilos.css';

// Importar filtros
import '@/app/filters'
