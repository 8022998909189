// import axios from '@/app/plugins/Axios';
import createCookie from '@/app/plugins/CookieCore';
import createAxios from '@/app/plugins/AxiosCore';
import config from '@/app/config';

const cookie = createCookie();
const axios = createAxios().create();


function createAuth() {

  function login(credenciais) {
    return axios.post('/login', credenciais)
      .then(response => {
        setUserData(response.data);
      });
      // .catch(error => {
      //   unsetUserData();
      // });
  }

  function  logout() {
    unsetUserData()
  }

  function check() {
    if (getToken()) {
      return true;
    } else {
      unsetUserData();
      return false;
    }
  }

  function setUserData(user) {
    window.localStorage.setItem(config.STORAGE_USER, JSON.stringify(user));
    setToken(user.api_token);
  }

  function unsetUserData() {
    window.localStorage.removeItem(config.STORAGE_USER);
    window.localStorage.removeItem(config.STORAGE_TOKEN);
    cookie.deleteCookie('api_token');
  }

  function setToken(api_token) {
    window.localStorage.setItem(config.STORAGE_TOKEN, api_token);
    cookie.setCookie('api_token', api_token, config.COOKIE_EXPIRATION_TIME);
  }

  function getToken() {
    let storage_token = window.localStorage.getItem(config.STORAGE_TOKEN);
    if (cookie.getCookie('api_token') == storage_token) {
      return cookie.getCookie('api_token');
    }

    return false;
    // return window.localStorage.getItem(config.STORAGE_TOKEN);
  }

  function getUser() {
    return JSON.parse(window.localStorage.getItem(config.STORAGE_USER));
  }

  function getAuthHeader() {
    return {
      'Authorization': 'Bearer ' + getToken()
    }
  }

  function getJWTDecode() {
    return JSON.parse(window.atob(getToken().split('.')[1]))
  }

  function getPerfil() {
    return getUser().perfil;
  }

  function getPermissoes() {
    return getPerfil().permissoes.split(',');
  }

  function temPermissao(permissao) {
    if (permissao == '' || permissao == null) {
      return true;
    }

    let permissoes = getPermissoes();
    let indice = permissoes.findIndex(p => p.trim() == permissao.trim());

    if (getPerfil().descricao == 'Administrador') {
      return true;
    }

    return indice > -1;
  }

  function isAdmin() {
    return this.getPerfil().descricao == "Administrador";
  }

  return {
    login,
    logout,
    check,
    getToken,
    getUser,
    getAuthHeader,
    getJWTDecode,
    getPerfil,
    temPermissao,
    p: temPermissao,
    isAdmin
  }

}

createAuth.install = function(Vue, options = {}) {
  Vue.prototype.$auth = createAuth();
}


// export {authPlugin};

export default createAuth;

