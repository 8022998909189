<template>
    <div>
        <v-container fluid>
            <!-- <h3 class="headline mb-3">Lista de Usuários</h3> -->

            <v-card>
                <v-toolbar flat>
                  <div style="width: 300px;">
                    <v-text-field label="Pesquisar" autofocus flat solo clearable hide-details prepend-inner-icon="mdi-magnify" v-model="search"></v-text-field>
                  </div>

                  <v-btn-toggle v-show="false" v-model="listagem" mandatory class="elevation-1">
                    <v-btn value="Recentes" text>
                      Recentes
                    </v-btn>
                    <v-btn value="Todos" text>
                      Todos
                    </v-btn>
                  </v-btn-toggle>
                  
                  <v-spacer></v-spacer>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" icon @click="listar" :loading="$store.state.loading">
                        <v-icon>mdi-refresh</v-icon>
                      </v-btn>
                    </template>
                    <span>Atualizar</span>
                  </v-tooltip>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" icon color="primary" to="/sistema/usuarios/adicionar">
                        <v-icon>mdi-account-plus</v-icon>
                      </v-btn>
                    </template>
                    <span>Adicionar Usuário</span>
                  </v-tooltip>
                </v-toolbar>

                <v-divider></v-divider>

                <v-data-table :headers="headers" :items="usuarios" item-key="id" :loading="false" :search="search" hide-default-footer disable-pagination>
                <template v-slot:item="{ item: usuario }">
                  <tr>

                    <!-- <td>{{ usuario.id }}</td> -->
                    <td>{{ usuario.name }}</td>
                    <td>{{ usuario.email }}</td>
                    <td>{{ usuario.perfil.descricao }}</td>
                    <td class="text-center">
                      <v-btn icon @click="editar(usuario)" class="ma-0">
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                      <v-btn icon @click="perguntarExcluir(usuario)" class="ma-0" v-if="usuario.name != 'Administrador'">
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </template>
                </v-data-table>
            </v-card>
        </v-container>


      <z-dialogo ref="dialogoExcluir" :dividers="false" largura="280px">
        
        <div slot="texto">
          <span class="subtitle-1">Excluir usuário?</span>
        </div>

        <v-btn color="grey darken-1" text @click="$refs.dialogoExcluir.fechar()" :disabled="excluindo">Cancelar</v-btn>
        <v-btn color="red darken-1" text dark @click="confirmarExcluir" :loading="excluindo" :disabled="excluindo">Excluir</v-btn>

      </z-dialogo>
    </div>
</template>

<script>
import { mapMutations, mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      // globals,
      headers: [
        // { text: "Código", value: "id", width: "150px" },
        { text: "Nome", value: "name" },
        { text: "Login", value: "email" },
        { text: "Tipo", value: "perfil.descricao" },
        { text: "Opções", value: "", width: "200px", align: "center", sortable: false }
      ],
      search: "",
      pagination: {},
      excluindo: false,
      usuario: {},
      listagem: 'Recentes'
    };
  },

  created() {
    this.setTitulo('Cadastro de Usuários');
    this.listar();
  },

  methods: {
    ...mapMutations(['setTitulo']),
    ...mapActions('usuarios', ['listar', 'excluir', 'abrir']),

    async editar(usuario) {
      await this.abrir(usuario.id);
      this.$router.push({ name: "UsuariosEditar", params: { id: usuario.id } });
    },

    perguntarExcluir(usuario) {
      this.usuario = usuario;
      this.$refs.dialogoExcluir.abrir();
    },

    async confirmarExcluir() {
      try {
        this.excluindo = true;
        await this.excluir(this.usuario.id);
      } catch(error) {
        console.log(error);
      }
      finally {
        this.$refs.dialogoExcluir.fechar();
        this.excluindo = false;
        this.$eventbus.notificar('Usuário excluído com sucesso!');
      }
    }
  },

  computed: {
    usuarios() {
      return this.$store.state.usuarios.usuarios.filter(usuario => {
        if (!this.$auth.isAdmin()) {
          return usuario.perfil.descricao.toLowerCase() !== 'administrador';
        } else {
          return true;
        }
      });
    }
  }
};
</script>

<style scoped>
</style>
