<template>
    <v-snackbar :timeout="timeout" :color="cor" bottom v-model="snackbar" vertical @input="chamarCallback">
        <span v-html="mensagem">{{ mensagem }}</span>
        <v-btn text @click.native="snackbar = false; chamarCallback()" :color="corBotao">Fechar</v-btn>
    </v-snackbar>
</template>

<script>
export default {

    data(){
        return {
          snackbar: false,
          mensagem: '',
          cor     : '',
          timeout : 3000,
          callback: null
        }
    },

    methods: {
        exibir(opcoes, callback) {
            this.mensagem = opcoes.mensagem || '';
            this.cor      = opcoes.cor || '';
            this.timeout  = opcoes.timeout != null ? opcoes.timeout : 3000;
            this.callback = opcoes.callback || null;

            this.snackbar = true;
        },

        fechar() {
            this.snackbar = false;
        },

        chamarCallback() {
          if (this.callback != null) {
            this.callback();
          }
        }
    },
    computed: {
      corBotao() {
        return this.cor == 'default' || this.cor == '' ? 'primary' : 'default';
      }
    }

}
</script>

<style>

</style>
