import usuariosApi from "@/app/services/usuarios";

export default {
    namespaced: true,
    state: {
        usuario: {},
        usuarios: [],
        perfis: []
    },

    getters: {
      usuariosSelect(state) {
        return state.usuarios.map(usuario => {
          return {
            text: usuario.id + ' - ' + usuario.name,
            value: usuario.id
          }
        })
      }
    },

    mutations: {
      listar(state, usuarios) {
        state.usuarios = usuarios;
      },

      abrir(state, usuario) {
        state.usuario = usuario;
      },

      salvar(state, usuario) {
        state.usuarios.push(usuario);
      },

      salvarPerfil(state, perfil) {
        state.perfis.push(perfil);
      },

      atualizar(state, usuario) {
        let index = state.usuarios.findIndex(usuario_ => usuario_.id == usuario.id);
        if (index > -1) {
          state.usuarios.splice(index, 1, usuario);
        }
      },

      atualizarPerfil(state, perfil) {
        let index = state.perfis.findIndex(p => p.id == perfil.id);
        if (index > -1) {
          state.perfis.splice(index, 1, perfil);
        }
      },

      excluir(state, id) {
        let index = state.usuarios.findIndex(usuario => usuario.id == id);
        if (index > -1) {
          state.usuarios.splice(index, 1);
        }
      },

      excluirPerfil(state, id) {
        let index = state.perfis.findIndex(perfil => perfil.id == id);
        if (index > -1) {
          state.perfis.splice(index, 1);
        }
      },

      perfis(state, perfis) {
        state.perfis = perfis;
      },

      UNSET_USUARIO(state) {
        state.usuario = null;
      }
    },

    actions: {
      async listar({commit}) {
        // commit('GLOBAL_LOADING', true, {root: true});
        let usuarios = await usuariosApi.listar();
        commit('listar', usuarios);
        // commit('GLOBAL_LOADING', false, {root: true});
      },

      async abrir({commit}, id) {
        commit('GLOBAL_LOADING', true, {root: true});
        let usuario = await usuariosApi.abrir(id);
        commit('abrir', usuario);
        commit('GLOBAL_LOADING', false, {root: true});
      },

      async salvar({commit}, usuario) {
        let novousuario = await usuariosApi.salvar(usuario);
        commit('salvar', novousuario);
      },

      async atualizar({commit}, usuario) {
        await usuariosApi.atualizar(usuario);
        commit('atualizar', usuario);
      },

      async excluir({commit}, id) {
        await usuariosApi.excluir(id);
        commit('excluir', id);
      },

      async perfis({commit}) {
        commit('GLOBAL_LOADING', true, {root: true});
        let perfis = await usuariosApi.perfis();
        commit('perfis', perfis);
        commit('GLOBAL_LOADING', false, {root: true});
      },

      async salvarPerfil({commit}, perfil) {
        let novoPerfil = await usuariosApi.salvarPerfil(perfil);
        commit('salvarPerfil', novoPerfil);
        return novoPerfil;
      },

      async atualizarPerfil({commit}, perfil) {
        await usuariosApi.atualizarPerfil(perfil);
        commit('atualizarPerfil', perfil);
      },

      async excluirPerfil({commit}, perfil) {
        await usuariosApi.excluirPerfil(perfil.id);
        commit('excluirPerfil', perfil.id);
      },
    }
}
