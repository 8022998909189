import zDialogo from '@/components/zDialogo'
import zDialogoSimNao from '@/components/zDialogoSimNao'
import zMensagem from '@/components/zMensagem'
import zLoading from '@/components/zLoading'
import zAguardar from '@/components/zAguardar'
import zCampoValor from '@/components/zCampoValor'
import zDatePicker from '@/components/zDatePicker'
import zDatePickerMenu from '@/components/zDatePickerMenu'
import zTimePicker from '@/components/zTimePicker'
import zDateNavigation from '@/components/zDateNavigation'
import zBusca from '@/components/zBusca'
import zCpfCnpj from '@/components/zCpfCnpj'
import zCep from '@/components/zCep'
import zToolbarDynamicMenu from "@/components/zToolbarDynamicMenu"
import zNotificacao from "@/components/zNotificacao"
import zCamera from "@/components/zCamera"
import zRichEditor from "@/components/zRichEditor"
import BarChart from "@/components/graficos/BarChart"
import PieChart from "@/components/graficos/PieChart"
import zCardTexto from "@/components/zCardTexto"

export default new class ZComponents {

  install(Vue, options) {

    Vue.component('zDialogo', zDialogo);
    Vue.component('zDialogoSimNao', zDialogoSimNao);
    Vue.component('zMensagem', zMensagem);
    Vue.component('zLoading', zLoading);
    Vue.component('zAguardar', zAguardar);
    Vue.component('zCampoValor', zCampoValor);
    Vue.component('zDatePicker', zDatePicker);
    Vue.component('zDatePickerMenu', zDatePickerMenu);
    Vue.component('zTimePicker', zTimePicker);
    Vue.component('zDateNavigation', zDateNavigation);
    Vue.component('zBusca', zBusca);
    Vue.component('zCpfCnpj', zCpfCnpj);
    Vue.component('zCep', zCep);
    Vue.component('zToolbarDynamicMenu', zToolbarDynamicMenu);
    Vue.component('zNotificacao', zNotificacao);
    Vue.component('zCamera', zCamera);
    Vue.component('zRichEditor', zRichEditor);
    Vue.component('BarChart', BarChart);
    Vue.component('PieChart', PieChart);
    Vue.component('zCardTexto', zCardTexto);

  }
}
