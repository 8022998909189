<template>

<v-dialog ref="dialog" v-model="exibir" :return-value.sync="hora" persistent lazy full-width width="290px">
  <v-text-field slot="activator" v-model="hora" v-bind="$attrs" prepend-inner-icon="access_time" readonly></v-text-field>
  <v-time-picker v-if="exibir" v-model="hora" format="24hr" full-width>
    <v-btn flat icon @click="setAgora">
      <v-icon>access_time</v-icon>
    </v-btn>
    <v-spacer></v-spacer>
    <v-btn flat @click="exibir = false">Cancelar</v-btn>
    <v-btn flat color="primary" @click="$refs.dialog.save(hora)">OK</v-btn>
  </v-time-picker>
</v-dialog>

</template>

<script>

export default {
    name: 'z-time-picker',

    props: {
      value: {
        type: String,
        default: null
      },
      transparente: {
        type: Boolean,
        default: false
      },
      raiseOnFocus: Boolean,
      flat: Boolean,
      readonly: Boolean,
      disabled: Boolean,
      min: String
    },

    data() {
        return {
          dateFormatted: null,
          menu: false,
          isFocused: false,
          exibir: false,
        }
    },

    methods: {
      setAgora() {
        console.log(this.horaAgora());
        this.hora = this.horaAgora();
      }
    },

    computed: {
      hora: {
        get () {
          return this.value;
        },
        set (hora) {
          this.$emit('input', hora);
        }
      },

      isToBeFlat() {
        if (this.flat && this.raiseOnFocus && this.isFocused) {
          return false;
        } else {
          return this.flat;
        }
      },

      isSm() {
        return this.$vuetify.breakpoint.smAndDown;
      },
    },

}

</script>

<style scoped>

</style>
