<template>
  <v-slide-y-reverse-transition>
    <v-card dark class="primary elevation-10 posicao-card">
      <v-card-text>
        <h3 class="headline"><b>Breakpoint</b>: {{ $vuetify.breakpoint.name }} ({{ window_width }}px)</h3>
        <h3 class="headline"><b>Route name</b>: {{ $route.name }}</h3>
        <h3 class="headline"><b>Path</b>: {{ $route.path }}</h3>
        <!-- <h3 class="headline">Mouse position: {{ x }}, {{ y }}</h3> -->
      </v-card-text>
    </v-card>
  </v-slide-y-reverse-transition>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue';
// import { useMouse } from '@/app/composables/mouse'

const window_width = ref(window.innerWidth);

const resize = (event) => {
  window_width.value = window.innerWidth;
};

window.addEventListener('resize', resize);

onUnmounted(() => {
  window.removeEventListener('resize', resize);
});

// const { x, y } = useMouse();
</script>

<style lang="scss" scoped>

.posicao-card {
  opacity: 0.4; 
  position: fixed; 
  right: 20px; 
  bottom: 10px;
}

</style>