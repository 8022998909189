import axios from 'axios'

import cookie from '@/app/plugins/Cookie'
import config from '@/app/config'

class AxiosPlugin {

  constructor() {

    this.instance = axios.create({
      baseURL: config.API_URL,
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Accept': 'application/json',
        // 'Content-Type': 'application/json'
      }
    });

    this.instance.interceptors.request.use(
      axiosConfig => {
        if (cookie.getCookie('api_token')) {
          axiosConfig.headers.Authorization = 'Bearer ' + cookie.getCookie('api_token');
        }
        return axiosConfig;
      }, 
      error => Promise.reject(error)
    );

    this.instance.interceptors.response.use(function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    }, function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      return Promise.reject(error);
    });

  }

  install(Vue, options) {
    Vue.prototype.$axios = this.instance;
  }
}

const axiosPlugin = new AxiosPlugin();

export {axiosPlugin};

export default axiosPlugin.instance;