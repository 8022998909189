<template>
  <v-navigation-drawer app right clipped stateless :temporary="this.$vuetify.breakpoint.smAndDown" v-bind="$attrs" :width="drawerWidth" :value="drawer">

    <v-toolbar dark flat color="primary" class="d-flex justify-center">
      <v-toolbar-title>
        <h2>
          <span>Drawer</span>
          <span class="font-weight-light"> Direita</span>
        </h2>
      </v-toolbar-title>
    </v-toolbar>

    <keep-alive>
      <v-slide-x-transition mode='out-in'>
        <component v-bind:is="rightDrawerMenu"></component>
      </v-slide-x-transition>
    </keep-alive>

    <!-- <app-menu :mini-variant="miniVariant"></app-menu> -->
  </v-navigation-drawer>
</template>

<script>


  export default {
    components: {
      'Filtro1': () => import('@/pages/filtros/Filtro1'),
      'Filtro2': () => import('@/pages/filtros/Filtro2')
    },
    
    props: {
      drawer: {
        type: Boolean,
        default: true
      },
    },
    
    data() {
      return {
        clipped: false,
        fixed: true,
        miniVariant: false,
        dataehora: ''
      }
    },

    mounted() {
      setInterval(() => {
        let data = new Date().toLocaleDateString();
        let hora = new Date().toLocaleTimeString('pt-BR', {hour: "numeric", minute: "numeric", second: "numeric"});
        this.dataehora = data + ' ' + hora;
      }, 1000);
    },

    computed: {
      drawerWidth() {
        return this.$vuetify.breakpoint.xs ? '100%' : '300px';
      },

      rightDrawerMenu() {
        return this.$store.state.rightDrawerMenu; 
      }
    },
  }
</script>

<style scoped>
</style>