const TIPOS_ESPECIFICACAO = [
  { text: 'Seção', value: 1 },
  { text: 'Requisitos do Usuário', value: 2 },
  { text: 'Requisitos do Sistema', value: 3 },
];

const STATUS_REQUISITO = [
  { text: 'Novo', value: 'novo'},
  { text: 'Revisão', value: 'revisao'},
  { text: 'Retrabalho', value: 'retrabalho'},
  { text: 'Esboço', value: 'esboco'},
  { text: 'Implementado', value: 'implementado'},
  { text: 'Finalizado', value: 'finalizado'},
  { text: 'Válido', value: 'valido'},
  { text: 'Não testável', value: 'naotestavel'},
  { text: 'Obsoleto', value: 'obsoleto'},
];

const TIPOS_REQUISITO = [
  { text: 'Funcional', value: 'funcional'},
  { text: 'Não Funcional', value: 'naofuncional'},
  { text: 'Caso de Uso', value: 'casodeuso'},
  { text: 'Informacional', value: 'informacional'},
  { text: 'Funcionalidade', value: 'funcionalidade'},
  { text: 'Interface', value: 'interface'},
  { text: 'Restrição', value: 'restricao'},
];

const STATUS_CASO_TESTE = [
  { text: 'Rascunho', value: 'rascunho'},
  { text: 'Pronto para Revisão', value: 'pronto_revisao'},
  { text: 'Revisão em Progresso', value: 'revisao_progresso'},
  { text: 'Precisa Alterações', value: 'precisa_alteracoes'},
  { text: 'Obsoleto', value: 'obsoleto'},
  { text: 'Futuro', value: 'futuro'},
  { text: 'Final', value: 'final'},
];

const PRIORIDADES_CASO_TESTE = [
  { text: 'Alta', value: 'alta'},
  { text: 'Média', value: 'media'},
  { text: 'Baixa', value: 'baixa'},
];

const TIPO_EXECUCAO = [
  { text: 'Manual', value: 'Manual'},
  { text: 'Automático', value: 'Automático'},
];


const globals = {
  TIPOS_ESPECIFICACAO,
  STATUS_REQUISITO,
  TIPOS_REQUISITO,
  STATUS_CASO_TESTE,
  PRIORIDADES_CASO_TESTE,
  TIPO_EXECUCAO
}

export {globals};

export default {
    install: (Vue, options) => {
        Vue.prototype.$globals = globals;
    }
};
