<template>
  <div class="d-flex justify-space-between">
    
    <v-btn flat icon @click="subDate" :disabled="disabled">
      <v-icon>chevron_left</v-icon>
    </v-btn>
    
    <v-menu :disabled="disabled" ref="menu" left :close-on-content-click="false" v-model="menu" lazy transition="scale-transition" origin="center center" offset-y full-width max-width="290px" min-width="290px">
      <v-btn slot="activator" flat round color="primary" :disabled="disabled">{{ dateFormatted }}</v-btn>
      <v-date-picker locale="pt-BR" v-model="date" no-title @input="menu = false"></v-date-picker>
    </v-menu>
    
    <v-btn flat icon @click="addDate" :disabled="disabled">
      <v-icon>chevron_right</v-icon>
    </v-btn>

    
  </div>
</template>

<script>
  export default {
    props: {
      value: {
        type: [String],
        default: null
      },
      disabled: Boolean
    },

    data: (vm) => ({
      menu: false,
    }),

    methods: {
      formatDate(date) {
        if (!date) return null

        const [year, month, day] = date.split('-')
        return `${day}/${month}/${year}`
      },

      parseDate(date) {
        if (!date) return null

        return date.getFullYear() + '-' + (date.getMonth() + 1).toString().padStart(2, '0') + '-' + date.getDate().toString().padStart(2, '0');
      },

      addDate() {
        const [year, month, day] = this.date.split('-')
        let data = new Date(year, month-1, day);
        data.setDate(data.getDate() + 1);

        this.date = this.parseDate(new Date(data));
      },

      subDate() {
        const [year, month, day] = this.date.split('-')
        let data = new Date(year, month-1, day);
        data.setDate(data.getDate() - 1);

        this.date = this.parseDate(new Date(data));
      }
    },

    computed: {
      date: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit('input', val);
        }
      },

      dateFormatted() {
        return this.formatDate(this.date);
      }
    },
  }
</script>

<style scoped>

</style>