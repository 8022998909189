import Vue from 'vue'
import Router from 'vue-router'

import UsuariosRoutes from './usuarios';
import PublicoRoutes from './publico';
import SistemaRotas from './sistema';
import PlanosRotas from './planos';
import RequisitosRotas from './requisitos';

import { guards } from './guards.js'

Vue.use(Router)

const router = new Router({
  mode: 'hash',
  routes: [
    ...UsuariosRoutes,
    ...PublicoRoutes,
    ...SistemaRotas,
    ...PlanosRotas,
    ...RequisitosRotas,
  ]
})

router.beforeEach(guards);

export default router;