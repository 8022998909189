import listasApi from "@/app/services/listas";

export default {
    namespaced: true,
    state: {
        clientes: [],
        vendedores: [],
        fornecedores: [],
        bancos: [],
        grupos: [],
        empresassolicitantes: [],
        setores: [],
        perfis:[],
        marcas:[],
        modelos:[],
        veiculos:[],
        locacoes:[]
    },

    getters: {
      clientes(state) {
        return state.clientes.map(cliente => {
          return {
            text: cliente.id + ' - ' + cliente.razao,
            value: cliente.id
          }
        })
      },
      vendedores(state) {
        return state.vendedores.map(vendedor => {
          return {
            text: vendedor.nome,
            value: vendedor.id
          }
        })
      },
      fornecedores(state) {
        return state.fornecedores.map(fornecedor => {
          return {
            text: fornecedor.id + ' - ' + fornecedor.razao,
            value: fornecedor.id
          }
        })
      },
      bancos(state) {
        return state.bancos.map(banco => {
          return {
            text: banco.codigo + ' - ' + banco.descricao,
            value: banco.codigo
          }
        })
      },
      grupos(state) {
        return state.grupos.map(grupo => {
          return {
            text: grupo.id + ' - ' + grupo.descricao,
            value: grupo.id
          }
        })
      },
      setores(state) {
        return state.setores.map(setor => {
          return {
            text: setor.id + ' - ' + setor.descricao,
            value: setor.id
          }
        })
      },

      empresassolicitantes(state) {
        return state.empresassolicitantes.map(empresasolicitante => {
          return {
            text: empresasolicitante.id + ' - ' + empresasolicitante.razao,
            value: empresasolicitante.id
          }
        })
      },

      perfis(state) {
        return state.perfis.map(perfil => {
          return {
            text: perfil.descricao,
            value: perfil.id
          }
        })
      },

      marcas(state) {
        return state.marcas.map(marca => {
          return {
            text: marca.descricao,
            value: marca.id
          }
        })
      },

      modelos(state) {
        return state.modelos.map(modelo => {
          return {
            text: modelo.descricao,
            value: modelo.id
          }
        })
      },

      veiculos(state) {
        return state.veiculos.map(veiculo => {
          return {
            text: veiculo.placa + ' - ' + veiculo.descricao,
            value: veiculo.id
          }
        })
      },

      locacoes(state) {
        return state.locacoes.map(locacao => {
          return {
            text: locacao.contrato,
            value: locacao.id
          }
        })
      },
    },

    mutations: {
      'SET_CLIENTES'(state, clientes) {
        state.clientes = clientes;
      },

      'SET_VENDEDORES'(state, vendedores) {
        state.vendedores = vendedores;
      },

      'SET_FORNECEDORES'(state, fornecedores) {
        state.fornecedores = fornecedores;
      },

      'SET_BANCOS'(state, bancos) {
        state.bancos = bancos;
      },

      'SET_GRUPOS'(state, grupos) {
        state.grupos = grupos;
      },
      'SET_SETORES'(state, setores) {
        state.setores = setores;
      },

      'SET_EMPRESAS_SOLICITANTES'(state, empresassolicitantes) {
        state.empresassolicitantes = empresassolicitantes;
      },

      'SET_PERFIS'(state, perfis) {
        state.perfis = perfis;
      },

      'SET_MARCAS'(state, marcas) {
        state.marcas = marcas;
      },

      'SET_MODELOS'(state, modelos) {
        state.modelos = modelos;
      },

      'SET_VEICULOS'(state, veiculos) {
        state.veiculos = veiculos;
      },

      'SET_LOCACOES'(state, locacoes) {
        state.locacoes = locacoes;
      },
    },

    actions: {
      async todas({commit, dispatch}) {
        dispatch('clientes');
        dispatch('vendedores');
        dispatch('fornecedores');
      },
      
      async clientes({commit}) {
        commit('GLOBAL_LOADING', true, {root: true});
        let clientes = await listasApi.clientes();
        commit('SET_CLIENTES', clientes);
        commit('GLOBAL_LOADING', false, {root: true});
      },
      
      async vendedores({commit}) {
        commit('GLOBAL_LOADING', true, {root: true});
        let vendedores = await listasApi.vendedores();
        commit('SET_VENDEDORES', vendedores);
        commit('GLOBAL_LOADING', false, {root: true});
      },

      async fornecedores({commit}) {
        commit('GLOBAL_LOADING', true, {root: true});
        let fornecedores = await listasApi.fornecedores();
        commit('SET_FORNECEDORES', fornecedores);
        commit('GLOBAL_LOADING', false, {root: true});
      },

      async bancos({commit}) {
        commit('GLOBAL_LOADING', true, {root: true});
        let bancos = await listasApi.bancos();
        commit('SET_BANCOS', bancos);
        commit('GLOBAL_LOADING', false, {root: true});
      },

      async grupos({commit}) {
        commit('GLOBAL_LOADING', true, {root: true});
        let grupos = await listasApi.grupos();
        commit('SET_GRUPOS', grupos);
        commit('GLOBAL_LOADING', false, {root: true});
      },

      async setores({commit}) {
        commit('GLOBAL_LOADING', true, {root: true});
        let setores = await listasApi.setores();
        commit('SET_SETORES', setores);
        commit('GLOBAL_LOADING', false, {root: true});
      },

      async empresassolicitantes({commit}) {
        commit('GLOBAL_LOADING', true, {root: true});
        let empresassolicitantes = await listasApi.empresassolicitantes();
        commit('SET_EMPRESAS_SOLICITANTES', empresassolicitantes);
        commit('GLOBAL_LOADING', false, {root: true});
      },

      async perfis({commit}) {
        commit('GLOBAL_LOADING', true, {root: true});
        let perfis = await listasApi.perfis();
        commit('SET_PERFIS', perfis);
        commit('GLOBAL_LOADING', false, {root: true});
      },

      async marcas({commit}) {
        commit('GLOBAL_LOADING', true, {root: true});
        let marcas = await listasApi.marcas();
        commit('SET_MARCAS', marcas);
        commit('GLOBAL_LOADING', false, {root: true});
      },

      async modelos({commit}) {
        commit('GLOBAL_LOADING', true, {root: true});
        let modelos = await listasApi.modelos();
        commit('SET_MODELOS', modelos);
        commit('GLOBAL_LOADING', false, {root: true});
      },

      async veiculos({commit}) {
        // commit('GLOBAL_LOADING', true, {root: true});
        let veiculos = await listasApi.veiculos();
        commit('SET_VEICULOS', veiculos);
        // commit('GLOBAL_LOADING', false, {root: true});
      },

      async locacoes({commit}) {
        // commit('GLOBAL_LOADING', true, {root: true});
        let locacoes = await listasApi.locacoes();
        commit('SET_LOCACOES', locacoes);
        // commit('GLOBAL_LOADING', false, {root: true});
      },
    }
}
