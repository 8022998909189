import {version} from '../../package.json';

const APP_VERSION = 'v' + version;
const APP_NAME    = 'FastTest';
const APP_TITLE   = process.env.VUE_APP_TITLE;
const APP_PREFIX  = 'fastfest_';

const IS_PRODUCTION = process.env.NODE_ENV === 'production';

const BASE_URL_DEVELOPMENT = 'http://localhost:8080/';
const BASE_URL_PRODUCTION  = process.env.BASE_URL;
const BASE_URL_PRINT_DEV   = 'http://localhost:8000/';

const BASE_URL       = IS_PRODUCTION ? BASE_URL_PRODUCTION: BASE_URL_DEVELOPMENT;
const BASE_URL_PRINT = IS_PRODUCTION ? BASE_URL_PRODUCTION: BASE_URL_PRINT_DEV;
const API_URL        = BASE_URL + 'api/v1';

const COOKIE_EXPIRATION_TIME = 24 * 60;
// const COOKIE_EXPIRATION_TIME = 0; //24 * 60;

const STORAGE_USER    = APP_PREFIX + 'user';
const STORAGE_TOKEN   = APP_PREFIX + 'token';
const STORAGE_ENCRYPT = false;

export default {
  APP_VERSION,
  APP_NAME,
  APP_TITLE,
  APP_PREFIX,
  BASE_URL_PRODUCTION,
  BASE_URL_PRINT,
  BASE_URL,
  API_URL,
  IS_PRODUCTION,
  COOKIE_EXPIRATION_TIME,
  STORAGE_USER,
  STORAGE_TOKEN,
  STORAGE_ENCRYPT
}

// export default {
//   install: (Vue, options) => {
//       Vue.prototype.$config = config;
//   }
// };