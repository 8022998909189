// import requisitosApi from "@/app/services/requisitos";
import axios from '@/app/plugins/Axios';

export default {
    namespaced: true,
    state: {
      suitedeteste: {},
      suitesdeteste: [],
      casodeteste: {},
      casosdeteste: [],
      passos: [],
      especificacoes: [],
      selecionados: [],
      requisitos_associados: [],
      tabelaCasosDeTeste: []
    },

    getters: {

    },

    mutations: {
      SET_SUITE(state, suitedeteste) {
        state.suitedeteste = suitedeteste;
      },

      LISTAR_SUITES(state, suitesdeteste) {
        state.suitesdeteste = suitesdeteste.map(suite => {
          return {
            ...suite,
            ativo: false
          }
        });
      },

      LISTAR_CASOS(state, casosdeteste) {
        state.casosdeteste = casosdeteste;
      },

      SALVAR_SUITE(state, suite) {
        state.suitesdeteste.push(suite);
        state.suitedeteste = suite;
      },

      SALVAR_CASO(state, caso) {
        state.casosdeteste.push(caso);
      },

      ATUALIZAR_SUITE(state, suite) {
        let index = state.suitesdeteste.findIndex(suite_ => suite_.id == suite.id);
        state.suitesdeteste[index].nome = suite.nome;
        state.suitesdeteste[index].detalhes = suite.detalhes;
      },

      ATUALIZAR_CASO(state, caso) {
        let index = state.casosdeteste.findIndex(caso_ => caso_.id == caso.id);
        state.casosdeteste[index] = caso;
      },

      SET_CASODETESTE(state, casodeteste) {
        state.casodeteste = casodeteste;
      },

      EXCLUIR_SUITE(state, suite) {
        let index = state.suitesdeteste.findIndex(suite_ => suite_.id == suite.id);
        state.suitesdeteste.splice(index, 1);
      },

      EXCLUIR_CASO(state, caso) {
        let index = state.casosdeteste.findIndex(caso_ => caso_.id == caso.id);
        state.casosdeteste.splice(index, 1);
      },

      SET_ESPECIFICACOES(state, especificacoes) {
        state.especificacoes = especificacoes.especificacoes;
        state.selecionados = especificacoes.selecionados;
      },

      SET_REQUISITOS_ASSOCIADOS(state, requisitos_associados) {
        state.requisitos_associados = requisitos_associados;
      },

      SET_TABELA(state, casosdeteste) {
        state.tabelaCasosDeTeste = casosdeteste;
      }
    },

    actions: {

      // CASOS DE TESTE
      async listar_casos_de_teste({commit}, suite_id) {
        let response = await axios.get('casos/' + suite_id);
        commit('LISTAR_CASOS', response.data);
      },
      async abrir_caso({commit}, caso_id) {
        let casodeteste = await axios.get('caso/' + caso_id).then(response => response.data);
        commit('SET_CASODETESTE', casodeteste);
      },
      async buscar_caso({commit}, search) {
        let response = await axios.get('casos/buscar?busca=' + search);
        commit('LISTAR_CASOS', response.data);
      },
      async salvar_caso({commit}, caso) {
        let response = await axios.post('caso', caso);
        commit('SALVAR_CASO', response.data);
      },
      async atualizar_caso({commit}, caso) {
        let response = await axios.put('caso/' + caso.id, caso);
        commit('ATUALIZAR_CASO', response.data);
      },
      async excluir_caso({commit}, caso) {
        await axios.delete('caso/' + caso.id);
        commit('EXCLUIR_CASO', caso);
      },
      async copiar_caso({commit}, params) {
        let caso_copia = await axios.post('caso/' + params.caso_id + '/copiar', params).then(response => response.data);
        return caso_copia;
      },

      // SUITES DE TESTE
      async listar_suites_de_teste({commit}, projeto_id) {
        let response = await axios.get('suites/' + projeto_id);
        commit('LISTAR_SUITES', response.data);
      },

      async abrir_suite({commit}, suite_id) {
        let response = await axios.get('suite/' + suite_id);
        commit('SET_SUITE', response.data);
      },

      async buscar_suite({commit}, search) {
        let response = await axios.get('suites/buscar?busca=' + search);
        commit('LISTAR_SUITES', response.data);
      },

      async salvar_suite({commit}, suite) {
        let response = await axios.post('suite', suite);
        commit('SALVAR_SUITE', response.data);
        return response.data;
      },

      async atualizar_suite({commit}, suite) {
        let response = await axios.put('suite/' + suite.id, suite);
        commit('ATUALIZAR_SUITE', response.data);
      },

      async excluir_suite({commit}, suite) {
        await axios.delete('suite/' + suite.id);
        commit('EXCLUIR_SUITE', suite);
      },

      // PASSOS DE TESTE
      async salvar_passo({commit}, passo) {
        let response = await axios.post('passo', passo);
        return response.data;
      },
      async atualizar_passo({commit}, passo) {
        let response = await axios.put('passo/' + passo.id, passo);
        return response.data;
      },
      async excluir_passo({commit}, passo) {
        await axios.delete('passo/' + passo.id);
      },

      // ASSOCIACAO
      async associar({commit}, requisitos) {
        await axios.post('casos/associar', requisitos);
      },

      async associarRequisitoSelecionado({commit}, request) {
        await axios.post('casos/associarrequisitoselecionado', request);
      },

      async listar_requisitos({commit}, request) {
        let response = await axios.get('casos/listarrequisitos', { params: { ...request } });
        commit('SET_ESPECIFICACOES', response.data);
      },

      async listarRequisitosAssociados({commit}, caso_id) {
        let response = await axios.get('casos/listarrequisitosassociados?caso_id='+ caso_id);
        commit('SET_REQUISITOS_ASSOCIADOS', response.data);
      },

      async listarTabelaCasosDeTeste({commit}, projeto_id) {
        let tabela = await axios.get('casos/listartabelacasosdeteste', {params: {projeto_id: projeto_id}}).then(response => response.data);
        commit('SET_TABELA', tabela);
      }
    }
}
