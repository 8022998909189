// import projetosApi from "@/app/services/projetos";
import axios from '@/app/plugins/Axios';

export default {
    namespaced: true,
    state: {
      projeto: {},
      projetos: [],
      projetos_ativos: [],
      semprojetos: false,
      projeto_atual: {},
      filtros: [
        { texto: 'Apenas ativos', conteudo: '', campo: 'ativo', valor: false, exibir: false },
        { texto: 'Projeto', conteudo: 'ID = 2', campo: 'id', valor: 2, exibir: false },
      ],
      naoMembros: [],
    },

    getters: {

    },

    mutations: {
      LISTAR(state, projetos) {
        state.projetos = projetos;
      },

      LISTAR_ATIVOS(state, projetos) {
        state.projetos_ativos = projetos;
      },

      SALVAR(state, projeto) {
        state.projetos.push(projeto);
      },

      ATUALIZAR(state, projeto) {
        let index = state.projetos.findIndex(projeto_ => projeto_.id == projeto.id);
        state.projetos[index] = projeto;
      },

      EXCLUIR(state, projeto) {
        let index = state.projetos.findIndex(projeto_ => projeto_.id == projeto.id);
        state.projetos.splice(index, 1);
      },

      PROJETO_ATUAL(state, projeto) {
        state.projeto_atual = projeto;
      },

      SET_PROJETO(state, projeto) {
        state.projeto = projeto;
      },

      NAOMEMBROS(state, naoMembros) {
        state.naoMembros = naoMembros;
      },

      REMOVER_MEMBRO(state, usuario_id) { 
        let index = state.projeto.usuarios.findIndex(usuario => usuario.id == usuario_id);
        state.projeto.usuarios.splice(index, 1);
      },

      UPDATE_MEMBROS(state, usuarios) {      
        state.projeto.usuarios = usuarios;
      }
    },

    actions: {
      async abrir({commit}, projeto_id) {
        let response = await axios.get('projetos/' + projeto_id);
        commit('SET_PROJETO', response.data);
      },

      async listar({commit}) {
        let response = await axios.get('projetos');
        commit('LISTAR', response.data);
      },

      async listar_ativos({commit}) {
        let response = await axios.get('projetos/ativos');
        commit('LISTAR_ATIVOS', response.data);
      },

      async buscar({commit}, search) {
        let response = await axios.get('projetos/buscar?busca=' + search);
        commit('LISTAR', response.data);
      },

      async salvar({commit}, projeto) {
        let response = await axios.post('projetos', projeto);
        commit('SALVAR', response.data);
      },

      async atualizar({commit}, projeto) {
        let response = await axios.put('projetos/' + projeto.id, projeto);
        commit('ATUALIZAR', response.data);
      },

      async excluir({commit}, projeto) {
        await axios.delete('projetos/' + projeto.id);
        commit('EXCLUIR', projeto);
      },

      async naoMembros({commit}, projeto) {
        const response = await axios.get(`projetos/${projeto.id}/naomembros`);
        commit('NAOMEMBROS', response.data);
      },

      async membros({commit}, projeto) {
        await axios.get(`projetos/${projeto.id}/membros`);
      },

      async sincronizarMembros({commit}, dados) {
        const response = await axios.post(`projetos/${dados.projeto.id}/membros`, {usuarios_ids: dados.usuarios_ids});
        commit('UPDATE_MEMBROS', response.data);
      },

      async removerMembro({commit}, dados) {
        await axios.delete(`projetos/${dados.projeto.id}/membros/${dados.usuario_id}`);
        commit('REMOVER_MEMBRO', dados.usuario_id);
      },
    }
}
