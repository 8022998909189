<template>
  <div class="text-center">
    <v-menu v-model="menu" :close-on-content-click="false" nudge-bottom="8" transition="slide-y-transition" offset-y>
      <template v-slot:activator="{ on }">
        <v-btn icon v-on="on">
          <v-icon>mdi-account</v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-list dense>
          <v-list-item>
            <v-list-item-avatar color="primary">
              <v-icon dark>mdi-account</v-icon>
              <!-- <img src="https://s.gravatar.com/avatar/3ce71d0a57d08dd7d5b3436e21009f3c?s=80" alt="Eduardo Tavares"> -->
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{ $auth.getUser().name }}</v-list-item-title>
              <v-list-item-subtitle>{{ $auth.getUser().perfil.descricao }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-list dense>
          <v-list-item @click="dark = !dark">
            <v-list-item-action>
              <v-switch color="primary" v-model="dark" @click="dark = !dark"></v-switch>
            </v-list-item-action>
            <v-list-item-title>Modo Escuro</v-list-item-title>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>
        
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn color="primary" text @click="sair">
            <v-icon>mdi-logout</v-icon>
            Sair
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>

<script>

  import LocalStorage from "@/app/utils/LocalStorage";
  export default {
    data: () => ({
      fav: true,
      menu: false,
      message: false,
      hints: true,
      dark: false
    }),

    mounted() {
      this.dark = this.$vuetify.theme.dark;
    },

    methods: {
      abrir() {
        this.menu = true;
        this.$emit('onAbrir');
      },

      sair() {
        this.$auth.logout();
        this.$store.commit('setLeftDrawerMenu', 'MenuInicial');
        this.$store.commit('LIMPAR_NAVEGACAO');
        this.$router.push('/login');
      }
    },

    watch: {
      dark(val, oldval) {
        this.$vuetify.theme.dark = val;
        LocalStorage.set('dark', val);
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>