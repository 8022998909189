<template>
    <div>
      <v-text-field :loading="loading" label="CEP" :value="value" mask="#####-###" return-masked-value @input="onInput" @focus="onFocus"></v-text-field>
      <!-- <input
        type="text"
        ref="input"
        autocomplete="off"
        :class="[css, {'has-error': erro}]"
        :value="value"
        :disabled="disabled"
        :readonly="readonly"
        @input="onInput"
        @focus="onFocus"
        @keyup="onKeyUp"
      > -->
    </div>
</template>

<script>

    import axios from 'axios'

    export default {
        props: {
            value: {},
            buscarcep: {
                type: Boolean,
                default: false
            },
            readonly: {
                type: Boolean,
                default: false
            },
            disabled: {
                type: Boolean,
                default: false
            },
        },

        data() {
            return {
                cep: this.value,
                loading: false
            }
        },

        mounted() {
            // if (this.value.length > 0) {
            //     this.formatarCEP(this.value)
            // }
        },

        methods: {
            onInput(cep) {
                // this.cep = this.formatarCEP(cep)
                this.$emit('input', cep)

                if (this.buscarcep) {
                    this.buscarCep(cep)
                }
            },

            onFocus(e) {
                setTimeout(function () {
                    e.target.select()
                }, 0)
            },

            onKeyUp(e){
                this.$emit('keyup', e);
            },

            formatarCEP(cep){
                cep = cep.replace(/\D/g,"")                    //Remove tudo o que não é dígito
                cep = cep.replace(/^(\d{5})(\d)/,"$1-$2")         //Esse é tão fácil que não merece explicações
                return cep.substring(0,9)
            },

            buscarCep(cep) {
                if(/^[0-9]{5}-[0-9]{3}$/.test(cep)){
                  this.loading = true;
                  axios.get("https://viacep.com.br/ws/" + cep + "/json/")
                      .then((response) => {
                        this.loading = false;
                        this.$emit('resultado', response.data)
                      })
                      .catch(error => {
                        this.loading = false;
                      })
                }
            },

            focus() {
                this.$refs.input.focus();
            }
        },

    }
</script>

<style scoped>
    /*.has-error {
        border-color: #dd4b39;
        box-shadow: none;
    }
    label.has-error {
        color: #dd4b39;
    }*/
</style>
