import Vue from "vue"

export default new Vue({
  methods: {

    emit(mensagem, valor){
      this.$emit(mensagem, valor);
    },

    onEmit(mensagem, callback){
      this.$on(mensagem, callback)
    },

    notificar(mensagem){
      this.$emit('EXIBIR_MENSAGEM', mensagem)
    },

    onNotificar(callback){
      this.$on('EXIBIR_MENSAGEM', callback)
    },

    erro(mensagem, error){
      let error_bag = {
        mensagem: mensagem,
        response: error.response ? error.response : null
      }
      this.$emit('EXIBIR_MENSAGEM_ERRO', error_bag)
    },

    erroSistema(mensagem){
      this.$emit('EXIBIR_MENSAGEM_SISTEMA', mensagem)
    },

    onErro(callback){
      this.$on('EXIBIR_MENSAGEM_ERRO', callback)
    }
  }
})
