// import releasesApi from "@/app/services/releases";
import axios from '@/app/plugins/Axios';

export default {
    namespaced: true,
    state: {
      release: {},
      releases: [],
    },

    getters: {

    },

    mutations: {
      LISTAR(state, releases) {
        state.releases = releases;
      },

      SALVAR(state, release) {
        state.releases.push(release);
      },

      ATUALIZAR(state, release) {
        let index = state.releases.findIndex(release_ => release_.id == release.id);
        state.releases[index] = release;
      },

      EXCLUIR(state, release) {
        let index = state.releases.findIndex(release_ => release_.id == release.id);
        state.releases.splice(index, 1);
      },

      ABRIR(state, release) {
        state.release = release;
      }
    },

    actions: {
      async listar({commit}, plano_id) {
        let response = await axios.get('planosdeteste/'+plano_id+'/releases');
        commit('LISTAR', response.data);
      },

      async abrir({commit}, release_id) {
        let response = await axios.get('releases/' + release_id);
        commit('ABRIR', response.data);
      },

      async buscar({commit}, request) {
        let response = await axios.get('planosdeteste/'+request.plano_id+'/releases/buscar?busca=' + request.search);
        commit('LISTAR', response.data);
      },

      async salvar({commit}, release) {
        let response = await axios.post('releases', release);
        commit('SALVAR', response.data);
      },

      async atualizar({commit}, release) {
        let response = await axios.put('releases/' + release.id, release);
        commit('ATUALIZAR', response.data);
      },

      async excluir({commit}, release) {
        await axios.delete('releases/' + release.id);
        commit('EXCLUIR', release);
      }
    }
}
