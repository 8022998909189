export default [
  /**
   * ESPECIFICAÇÕES E REQUISITOS
   */
  {
    path: '/especificacoes',
    name: 'especificacoes',
    component: () => import(/* webpackChunkName: "projetos" */ '@/pages/requisitos/Especificacoes.vue'),
    meta: {layout: 'principal', permissao: 'requisitos'}
  },

  {
    path: '/especificacoes/adicionar',
    name: 'especificacoes_adicionar',
    component: () => import(/* webpackChunkName: "projetos" */ '@/pages/requisitos/EspecificacaoForm.vue'),
    meta: {layout: 'principal', permissao: 'requisitos'}
  },

  {
    path: '/especificacoes/:especificacao_id/editar',
    name: 'especificacoes_editar',
    component: () => import(/* webpackChunkName: "projetos" */ '@/pages/requisitos/EspecificacaoForm.vue'),
    meta: {editando: true, layout: 'principal', permissao: 'requisitos'}
  },

  {
    path: '/especificacoes/:especificacao_id/requisitos',
    name: 'requisitos',
    component: () => import(/* webpackChunkName: "projetos" */ '@/pages/requisitos/Requisitos.vue'),
    meta: {layout: 'principal', permissao: 'requisitos'}
  },
  
  {
    path: '/especificacoes/:especificacao_id/requisitos/adicionar',
    name: 'requisitos_adicionar',
    component: () => import(/* webpackChunkName: "requisitos" */ '@/pages/requisitos/RequisitoForm.vue'),
    meta: {layout: 'principal', permissao: 'requisitos'}
  },

  {
    path: '/especificacoes/:especificacao_id/requisitos/:requisito_id/editar',
    name: 'requisitos_editar',
    component: () => import(/* webpackChunkName: "requisitos" */ '@/pages/requisitos/RequisitoForm.vue'),
    meta: {editando: true, layout: 'principal', permissao: 'requisitos'}
  },

  {
    path: '/especificacoes/:especificacao_id/requisitos/:requisito_id/visualizar',
    name: 'requisitos_visualizar',
    component: () => import(/* webpackChunkName: "requisito_visualizar" */ '@/pages/requisitos/RequisitoVisualizar.vue'),
    meta: {layout: 'principal', permissao: 'requisitos'}
  },

  {
    path: '/especificacoes/tabelarfv',
    name: 'tabelarfv',
    component: () => import(/* webpackChunkName: "tabelarfv" */ '@/pages/requisitos/TabelaRFV.vue'),
    meta: {layout: 'principal', permissao: 'requisitos'}
  },
];