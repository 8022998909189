import UsuariosLista from '@/pages/usuarios/UsuariosLista'
import UsuariosForm from '@/pages/usuarios/UsuariosForm'
import UsuariosPermissoes from '@/pages/usuarios/UsuariosPermissoes'

export default [
  {
    path: '/sistema/usuarios', 
    name: 'UsuariosLista', 
    component: UsuariosLista,
    meta: {layout: 'principal', permissao: 'usuarios'}
  },
  
  {
    path: '/sistema/usuarios/adicionar', 
    name: 'UsuariosAdicionar', 
    component: UsuariosForm,
    meta: {layout: 'principal', permissao: 'usuarios'}
  },
  
  {
    path: '/sistema/usuarios/:id/editar', 
    name: 'UsuariosEditar', 
    component: UsuariosForm,
    meta: {editando: true, layout: 'principal', permissao: 'usuarios'}
  },

  {
    path: '/sistema/permissoes', 
    name: 'Permissoes', 
    component: UsuariosPermissoes,
    meta: {layout: 'principal', permissao: 'permissoes'}
  },
];
