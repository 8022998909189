<template>
    <v-text-field ref="input" :value="value" @input="onInput" maxlength="18" @focus="onFocus" v-bind="$attrs"></v-text-field>
</template>

<script>
    export default {
        props: {
            value: {}
        },

        data() {
            return {
                cpfcnpj: this.value
            }
        },

        methods: {
            onInput(cpfcnpj) {
                if (cpfcnpj.replace(/\D/g, "").length > 11) {
                    this.cpfcnpj = this.formataCNPJ(cpfcnpj)
                } else {
                    this.cpfcnpj = this.formataCPF(cpfcnpj)
                }

                this.$emit('input', this.cpfcnpj)
            },
            
            onFocus(e) {
                setTimeout(() => {
                    e.target.select();
                }, 0)
            },
            
            onKeyUp(e){
                this.$emit('keyup', e);
            },
            
            formataCPFCNPJ() {
                if (this.$refs.input.value.replace(/\D/g, "").length > 11) {
                    this.$refs.input.value = this.formataCNPJ(this.$refs.input.value)
                } else {
                    this.$refs.input.value = this.formataCPF(this.$refs.input.value)
                }
            },

            formataCNPJ(cnpj) {
                cnpj = cnpj.replace(/\D/g, "")                           //Remove tudo o que não é dígito
                cnpj = cnpj.replace(/^(\d{2})(\d)/, "$1.$2")             //Coloca ponto entre o segundo e o terceiro dígitos
                cnpj = cnpj.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3") //Coloca ponto entre o quinto e o sexto dígitos
                cnpj = cnpj.replace(/\.(\d{3})(\d)/, ".$1/$2")           //Coloca uma barra entre o oitavo e o nono dígitos
                cnpj = cnpj.replace(/(\d{4})(\d)/, "$1-$2")              //Coloca um hífen depois do bloco de quatro dígitos
                return cnpj.substring(0, 18);
            },

            formataCPF(cpf) {
                cpf = cpf.replace(/\D/g, "")                    //Remove tudo o que não é dígito
                cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2")       //Coloca um ponto entre o terceiro e o quarto dígitos
                cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2")       //Coloca um ponto entre o terceiro e o quarto dígitos
                //de novo (para o segundo bloco de números)
                cpf = cpf.replace(/(\d{3})(\d{1,2})$/, "$1-$2") //Coloca um hífen entre o terceiro e o quarto dígitos
                return cpf.substring(0, 14);
            },

            focus() {
                this.$refs.input.focus();
            }
        },
    }
</script>

<style scoped>
    .has-error {
        border-color: #dd4b39;
        box-shadow: none;
    }
    label.has-error {
        color: #dd4b39;
    }
</style>