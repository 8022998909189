<template>
  <v-navigation-drawer app fixed permanent stateless disable-resize-watcher width="280px" v-bind="$attrs" :value="drawer" @input="alternarDrawer">

    <v-toolbar flat dark color="primary" class="d-flex justify-center">
      <v-toolbar-title>
        <h2>
          <span>FAST</span>
          <span class="font-weight-light"> TEST</span>
        </h2>
      </v-toolbar-title>
    </v-toolbar>

    <keep-alive>
      <v-slide-x-transition mode='out-in'>
        <component v-bind:is="leftDrawerMenu" style="height: 100%; height: calc(100% - 156px)"></component>
      </v-slide-x-transition>
    </keep-alive>

    <template #append>
      <div class="pa-1 text-center text-body-2">
        <span>{{ $config.APP_VERSION }}</span>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>

  import AppMenu from "./AppMenu";

  export default {
    components: {
      'MenuExecutarTestes': () => import('@/pages/menus/MenuExecutarTestes'),
      'MenuInicial': () => import('@/pages/menus/MenuInicial'),
      'MenuProjeto': () => import('@/pages/menus/MenuProjeto'),
      'MenuSuitesDeTeste': () => import('@/pages/menus/MenuSuitesDeTeste'),
      'MenuPlanoTeste': () => import('@/pages/menus/MenuPlanoTeste'),
      'MenuEspecificacoes': () => import('@/pages/menus/MenuEspecificacoes'),
    },
    
    props: {
      drawer: {
        type: Boolean,
        default: true
      },
      // miniVariant: {
      //   type: Boolean,
      //   default: false
      // }
    },
    
    data() {
      return {
        clipped: false,
        fixed: true,
        miniVariant: false,
        dataehora: '',
      }
    },

    mounted() {
      setInterval(() => {
        let data = new Date().toLocaleDateString();
        let hora = new Date().toLocaleTimeString('pt-BR', {hour: "numeric", minute: "numeric", second: "numeric"});
        this.dataehora = data + ' ' + hora;
      }, 1000);
    },

    methods: {
      alternarDrawer(event) {
        this.$emit('update:drawer', event);
      }
    },


    computed: {
      leftDrawerMenu() {
        return this.$store.state.leftDrawerMenu; 
      }
    },
  }
</script>

<style scoped>
  /* width */
  ::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888; 
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
</style>