import axios from '@/app/plugins/Axios'

export default new class UsuariosService {

  listar() {
    return axios.get('usuarios')
      .then(response => response.data);
  }

  abrir(id) {
    return axios.get('/usuarios/' + id)
      .then(response => response.data);
  }

  salvar(vendedor) {
    return axios.post('/usuarios', vendedor)
      .then(response => response.data);
  }

  atualizar(vendedor) {
    return axios.put('/usuarios/'+ vendedor.id, vendedor)
      .then(response => response.data)
  }

  excluir(id) {
    return axios.delete('/usuarios/' + id)
      .then(response => response.data);
  }

  perfis() {
    return axios.get('/perfis')
      .then(response => response.data);
  }

  salvarPerfil(perfil) {
    return axios.post('/perfis', perfil)
    .then(response => response.data);
  }

  atualizarPerfil(perfil) {
    return axios.put('/perfis/' + perfil.id, perfil)
    .then(response => response.data);
  }

  excluirPerfil(id) {
    return axios.delete('/perfis/' + id)
      .then(response => response.data);
  }
}
