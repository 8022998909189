export default [
  /**
   * PLANOS DE TESTE
   */
  {
    path: '/planosdeteste',
    name: 'planosdeteste',
    component: () => import(/* webpackChunkName: "planosdeteste" */ '@/pages/planos/PlanosDeTeste.vue'),
    meta: {layout: 'principal', permissao: 'planos'}
  },


  {
    path: '/planosdeteste/adicionar',
    name: 'planosdeteste_adicionar',
    component: () => import(/* webpackChunkName: "planosdeteste" */ '@/pages/planos/PlanoDeTesteForm.vue'),
    meta: {layout: 'principal', permissao: 'planos'}
  },

  {
    path: '/planosdeteste/:plano_id/editar',
    name: 'planosdeteste_editar',
    component: () => import(/* webpackChunkName: "planosdeteste" */ '@/pages/planos/PlanoDeTesteForm.vue'),
    meta: {editando: true, layout: 'principal', permissao: 'planos'}
  },

  {
    path: '/planosdeteste/:plano_id',
    name: 'planosdeteste_selecionado',
    component: () => import(/* webpackChunkName: "releases" */ '@/pages/planos/PlanoSelecionado.vue'),
    meta: {layout: 'principal', permissao: 'planos'},
    children: [
      {
        path: 'gerenciar',
        name: 'planosdeteste_gerenciar',
        component: () => import(/* webpackChunkName: "planosdeteste" */ '@/pages/planos/GerenciarPlanoDeTeste.vue')
      },
      {
        path: 'releases',
        name: 'releases_lista',
        component: () => import(/* webpackChunkName: "releases_adicionar" */ '@/pages/planos/Releases.vue')
      },
      {
        path: 'reseases/adicionar',
        name: 'releases_adicionar',
        component: () => import(/* webpackChunkName: "releases_adicionar" */ '@/pages/planos/ReleaseForm.vue')
      },
      {
        path: 'releases/:release_id/editar',
        name: 'releases_editar',
        component: () => import(/* webpackChunkName: "releases_editar" */ '@/pages/planos/ReleaseForm.vue'),
        meta: {
          editando: true
        }
      },
      {
        path: 'selecionar',
        name: 'planosdeteste_selecionar',
        component: () => import(/* webpackChunkName: "selecionar" */ '@/pages/planos/SelecionarCasosDeTeste.vue')
      },
      {
        path: 'priorizar',
        name: 'priorizar_casos_de_teste',
        component: () => import(/* webpackChunkName: "selecionar" */ '@/pages/planos/PriorizarCasosDeTeste.vue')
      }

    ]
  },

  {
    path: '/planosdeteste/semplanos',
    name: 'planosdeteste_semplanos',
    component: () => import(/* webpackChunkName: "planosdeteste" */ '@/pages/planos/SemPlanos.vue'),
    meta: {layout: 'principal', permissao: 'planos'}
  },

  {
    path: '/planosdeteste/:plano_id/execucaoordenada',
    name: 'execucao_ordenada',
    component: () => import(/* webpackChunkName: "selecionar" */ '@/pages/planos/ExecucaoOrdenada.vue')
  },
];
