<template>

  <div>
    <v-list nav dense>
      <v-list-item-group v-model="item" color="primary">
        <v-list-item v-for="(item, i) in items" :key="i" :to="item.path">
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title v-text="item.text"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-group no-action prepend-icon="mdi-file-cabinet" group="tabelas">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Tabelas</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item @click="setTitulo('Prioridades')">
            <v-list-item-content>
              <v-list-item-title>Prioridades</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="setTitulo('Etiquetas')">
            <v-list-item-content>
              <v-list-item-title>Etiquetas</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-divider></v-divider>

        <v-list-group no-action prepend-icon="mdi-settings" group="sistema">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Sistema</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-switch color="primary" style="margin-left: 64px" class="mt-0 mb-3" label="Modo Escuro" v-model="dark" hide-details></v-switch>

          <v-list-item @click="setTitulo('Usuários')">
            <v-list-item-content>
              <v-list-item-title>Usuários</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="setTitulo('Permissões')">
            <v-list-item-content>
              <v-list-item-title>Permissões</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="setTitulo('Configurações')">
            <v-list-item-content>
              <v-list-item-title>Configurações</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-divider></v-divider>

        <v-list-item @click="sair">
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Sair</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </div>

</template>

<script>
  export default {
    props: {
      miniVariant: {
        type: Boolean
      }
    },

    data: () => ({
      item: 0,
      items: [
        { text: 'Dashboard', icon: 'mdi-home', path: '/' },
        { text: 'Projetos', icon: 'mdi-folder', path: '/projetos' },
        { text: 'Requisitos do Sistema', icon: 'mdi-account-multiple' },
        { text: 'Casos de Uso', icon: 'mdi-star' },
        { text: 'Suites de Teste', icon: 'mdi-history' },
        { text: 'Casos de Teste', icon: 'mdi-check-circle' },
        { text: 'Planos de Teste', icon: 'mdi-upload' },
        { text: 'Releases/Baseline', icon: 'mdi-cloud-upload' },
      ],
      dark: false
    }),

    mounted() {
      this.carregarLocalStorage();
    },

    methods: {
      setTitulo(titulo) {
        this.$store.commit('setTitulo', titulo)
      },

      carregarLocalStorage() {
        let dark = localStorage.getItem(this.$config.APP_PREFIX + '_dark');
        console.log(dark);
        this.dark = dark == 'true' ? true : false;
      },

      sair() {
        setTimeout(() => {
          this.$auth.logout();
          this.$router.push('/login');
        }, 1000);
      }
    },

    watch: {
      dark(val, oldval) {
        this.$vuetify.theme.dark = val;
        localStorage.setItem(this.$config.APP_PREFIX + '_dark', val);
      }
    }
  }
</script>

<style scoped>

</style>
