<template>
<div>
  <v-dialog v-model="exibir" persistent :max-width="largura" :scrollable="scrollable">
    <v-card>
      <v-card-title v-show="titulo" class="pa-3 subtitle-2">
       {{titulo}}
      </v-card-title>

      <v-divider v-if="dividers"></v-divider>

      <v-card-text class="pa-4">
        <slot name="texto">
         {{texto}}
        </slot>
      </v-card-text>

      <v-divider v-if="dividers"></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <slot></slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</div>
</template>

<script>
    export default {
        name: 'z-dialogo',

        props: {
            titulo: {
                type: String,
                default: null
            },
            texto: {
                type: String,
                default: null
            },
            largura: {
                type: String,
                default: "400px"
            },
            scrollable: {
                type: Boolean,
                default: false
            },
            dividers: {
                type: Boolean,
                default: false
            },
            altura: {
                type: String,
                default: null
            }
        },

        data() {
            return {
                exibir: false
            }
        },

        methods: {
            abrir() {
                this.exibir = true;
                this.$emit('abrir');
            },

            fechar() {
                this.exibir = false;
                this.$emit('fechar');
            }
        },

        computed: {
            alturaCard() {
                if (this.altura) {
                    return 'height: ' + this.altura;
                }
                return '';
            }
        }

    }
</script>

<style scoped>

</style>
