// import requisitosApi from "@/app/services/requisitos";
import axios from '@/app/plugins/Axios';

export default {
    namespaced: true,
    state: {
      requisito: {
        criador: {
          name: ''
        },
        atualizador: {
          name: ''
        }
      },
      requisitos: [],
      suites: [],
      selecionados: [],
      cobertura: [],
      tabelaRFV: []
    },

    getters: {

    },

    mutations: {
      LISTAR(state, requisitos) {
        state.requisitos = requisitos;
      },

      SET_REQUISITO(state, requisito) {
        state.requisito = requisito;
      },
      
      SALVAR(state, requisito) {
        state.requisitos.push(requisito);
      },
      
      ATUALIZAR(state, requisito) {
        let index = state.requisitos.findIndex(requisito_ => requisito_.id == requisito.id);
        // state.requisitos[index].titulo = requisito.titulo;
        // state.requisitos[index].chave = requisito.chave;
      },

      ATUALIZAR_FATORES(state, requisito) {
        state.requisito.CP  = requisito.CP;
        state.requisito.CI  = requisito.CI;
        state.requisito.RC  = requisito.RC;
        state.requisito.FP  = requisito.FP;
        state.requisito.RFV = requisito.RFV;
      },
      
      EXCLUIR(state, requisito) {
        let index = state.requisitos.findIndex(requisito_ => requisito_.id == requisito.id);
        state.requisitos.splice(index, 1);
      },

      
      SET_SUITES(state, suites) {
        state.suites = suites.suites;
        state.selecionados = suites.selecionados;
      },

      SET_COBERTURA(state, cobertura) {
        state.cobertura = cobertura;
      },

      SET_TABELARFV(state, tabelaRFV) {
        state.tabelaRFV = tabelaRFV;
      },
    },

    actions: {
      async listar({commit}, especificacao_id) {
        let response = await axios.get('especificacoes/'+ especificacao_id +'/requisitos');
        commit('LISTAR', response.data);
      },

      async abrir({commit}, requisito_id) {
        let requisito = await axios.get('requisitos/' + requisito_id).then(response => response.data);
        commit('SET_REQUISITO', requisito);
      },

      async buscar({commit}, search) {
        let response = await axios.get('requisitos/buscar?busca=' + search);
        commit('LISTAR', response.data);
      },

      async salvar({commit}, requisito) {
        let response = await axios.post('requisitos', requisito);
        commit('SALVAR', response.data);
      },

      async atualizar({commit}, requisito) {
        let response = await axios.put('requisitos/' + requisito.id, requisito);
        commit('ATUALIZAR', response.data);
      },

      async salvarFatores({commit}, requisito) {
        let requisito_ = await axios.put('requisitos/' + requisito.id + '/salvarfatores', requisito).then(response => response.data);
        commit('ATUALIZAR_FATORES', requisito_);
      },

      async excluir({commit}, requisito) {
        await axios.delete('requisitos/' + requisito.id);
        commit('EXCLUIR', requisito);
      },

      async copiar({commit}, params) {
        let requisito_copia = await axios.post('requisito/' + params.requisito_id + '/copiar', params).then(response => response.data);
        return requisito_copia;
      },

      // ASSOCIACAO
      // async associar({commit}, requisitos) {
      //   await axios.post('requisitos/associar', requisitos);
      // },

      async associarCasoSelecionado({commit}, request) {
        await axios.post('requisitos/associarcasoselecionado', request);
      },

      async listar_casos({commit}, request) {
        let response = await axios.get('requisitos/listarcasos', { params: { ...request } });
        commit('SET_SUITES', response.data);
      },

      async listarCobertura({commit}, requisito_id) {
        let cobertura = await axios.get('requisitos/listarcobertura?requisito_id=' + requisito_id).then(response => response.data);
        commit('SET_COBERTURA', cobertura);
      },

      async listarTabelaRFV({commit}, projeto_id) {
        let tabelaRFV = await axios.get('requisitos/tabelarfv', {params: {projeto_id: projeto_id}}).then(response => response.data);
        commit('SET_TABELARFV', tabelaRFV);
      }

    }
}
