<template>
  <div>
    <v-slide-y-transition mode="out-in">
      <layout-principal v-if="!$route.meta.public"></layout-principal>
      <template v-else>
        <transition>
          <keep-alive>
            <router-view></router-view>
          </keep-alive>
        </transition>
      </template>
    </v-slide-y-transition>
  </div>
</template>

<script>
import LayoutPrincipal from "@/pages/layout/AppLayout.vue";

export default {
  name: "App",
  components: {
    LayoutPrincipal
  },

  data() {
    return {};
  },

  // created() {
  //   this.$axios.interceptors.response.use(undefined, function (err) {
  //     return new Promise(function (resolve, reject) {
  //       if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
  //         this.$auth.logout();
  //         this.$router.push('/login');
  //       }
  //       throw err;
  //     });
  //   });
  // },

  created() {
    // this.$store.dispatch('carregarConfiguracoes');
  },

  mounted() {
    // if (this.$auth.check()) {
    //   console.log('Está logado');
    // } else {
    //   console.log('NÃO está logado');
    // }
    // this.init();
  },

  methods: {
    init() {
      if (this.$config.COOKIE_EXPIRATION_TIME == 0) {
        window.onunload = () => {
          console.log('Saindo');
          this.$cookie.deleteCookie('api_token');
        }
      }
    }
  },
};
</script>

<style>

  .x-small {
      font-size: 11px;
      height: 18px;
  }

</style>
