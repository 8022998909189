<template>
  <v-app>

    <app-drawer-direita :drawer="rightDrawer"></app-drawer-direita>

    <v-app-bar flat app color="primary" dark fixed clipped-right>
      <!-- <v-app-bar-nav-icon @click.stop="onSideIconClick" v-show="navIcon"></v-app-bar-nav-icon> -->
      <v-toolbar-title class="headline text-uppercase_">{{ tituloTela }}</v-toolbar-title>
      <!-- <v-toolbar-title>
        <h2>
          <span>FAST</span>
          <span class="font-weight-light"> TEST</span>
        </h2>
      </v-toolbar-title> -->
      
      <v-progress-circular v-show="loading" dark indeterminate :size="25" :width="3" class="ml-4"></v-progress-circular>

      <v-spacer></v-spacer>

      <!-- <v-btn text @click.stop="onSideIconClick2">Drawer Direita</v-btn> -->
      <!-- <v-btn text @click.stop="$store.commit('setLeftDrawerMenu', 'MenuInicial')">Menu Inicial</v-btn> -->
      <!-- <v-btn text @click.stop="$store.commit('setLeftDrawerMenu', 'MenuProjeto')">Menu Projeto</v-btn> -->
      <!-- <v-btn text @click.stop="$store.commit('setLeftDrawerMenu', 'MenuExecutarTestes')">MenuExecutarTestes</v-btn> -->
      <!-- <span v-if="!config.IS_PRODUCTION" class="hidden-sm-and-down">[ {{ $vuetify.breakpoint.name }} ]</span> -->
      
      <v-toolbar-items>
        <escolher-projeto></escolher-projeto>
      </v-toolbar-items>

      <user-menu></user-menu>
    </v-app-bar>

    <app-drawer :drawer.sync="leftDrawer"></app-drawer>

    <v-main>

      <v-container style="margin-bottom: 200px;">
        <v-row justify="center" no-gutters>
          <v-col cols="12" lg="11">

          <v-scroll-y-transition mode='out-in'>
            <router-view/>
          </v-scroll-y-transition>

          </v-col>
        </v-row>
      </v-container>

    </v-main>

    <v-snackbar bottom v-model="snackbar.exibir" :timeout="snackbar.timeout" :color="snackbar.color">
      {{ snackbar.mensagem }}
      <v-btn dark text color="primary" @click.native="snackbar.exibir = false">Fechar</v-btn>
      <v-btn dark text color="primary" @click.native="snackbar.exibir = false">Detalhes</v-btn>
    </v-snackbar>

    <z-notificacao ref="notificacao"></z-notificacao>

    <z-dialogo ref="dialogoErro" titulo="Erro" dividers>
      <template v-slot:texto>
        <v-container>
          <v-layout>
            Teste
          </v-layout>
        </v-container>
      </template>
      <v-btn color="primary" @click="$refs.dialogoErro.fechar()">OK</v-btn>
    </z-dialogo>

    <z-debug-info v-if="!IS_PRODUCTION"></z-debug-info>

  </v-app>
</template>

<script>
import AppDrawer from "./AppDrawer";
import AppDrawerDireita from "./AppDrawerDireita";
import UserMenu from "./UserMenu";
import EscolherProjeto from "./EscolherProjeto";

import storage from "@/app/utils/LocalStorage";

import events from "@/app/mixins/events";
import config from "@/app/config";
import zDebugInfo from "@/components/zDebugInfo";


import { mapState } from 'vuex';

export default {
  name: "app-layout",
  mixins: [events],
  components: {
    AppDrawer,
    AppDrawerDireita,
    UserMenu,
    EscolherProjeto,
    zDebugInfo
  },
  data() {
    return {
      IS_PRODUCTION: config.IS_PRODUCTION,
      config: config,
      drawer: this.$vuetify.breakpoint.mdAndUp,
      fixed: false,
      title: "Tela Inicial",

      snackbar: {
        exibir: false,
        mensagem: '',
        timeout: 3000,
        color: ''
      },

    }
  },

  created() {
    this.$vuetify.theme.dark = storage.get('dark');
  },

  mounted() {
    // this.$store.dispatch('listas/todas');
    // this.$store.dispatch('carregarConfiguracoes');

    this.init();
  },

  methods: {

    async init() {
      await this.listarProjetos();

      if (this.projetos.length == 0) {
        this.$store.commit('setLeftDrawer', false);
        this.$store.commit('setNavIcon', false);
      } else {
        this.$store.commit('setLeftDrawer', true);
        this.$store.commit('setNavIcon', true);
        this.$store.commit('setLeftDrawerMenu', 'MenuInicial');
        this.carregarProjeto();
      }

      if (this.$route.path != '/') {
        this.$router.replace('/');
      }
    },

    carregarProjeto() {
      let projeto = storage.get('projeto');
      if (projeto != null) {
        this.$store.commit('projetos/PROJETO_ATUAL', projeto);
      } else {
        if (this.projetos.length > 0) {
          storage.set('projeto', this.projetos[0]);
          this.$store.commit('projetos/PROJETO_ATUAL', this.projetos[0]);
        }
      }
    },

    async listarProjetos() {
      try {
        await this.$store.dispatch('projetos/listar_ativos');
      } catch (error) {
        this.$eventbus.erroSistema('Não foi possível listar os projetos.');
      }
    },

    onSideIconClick() {
      this.$store.commit('setLeftDrawer', !this.leftDrawer);
    },
    onSideIconClick2() {
      this.$store.commit('setRightDrawer', !this.rightDrawer);
    },

    navegar(evento) {
      console.log(evento);
    }

  },

  computed: {
    ...mapState({
      navIcon: state => state.navIcon,
      leftDrawer: state => state.leftDrawer,
      rightDrawer: state => state.rightDrawer,
      tituloTela: state => state.titulo_tela,
      projeto: state => state.projetos.projeto_atual,
      projetos: state => state.projetos.projetos_ativos,
      navegacao: state => state.navegacao,
    }),

    leftDrawer: {
      get() {
        return this.$store.state.leftDrawer;
      },
      set(leftDrawer)  {
        this.$store.commit('setLeftDrawer', leftDrawer);
      }
    },

    loading() {
      return this.$store.getters.GLOBAL_LOADING;
    },
  },

};
</script>

<style>
  .theme--light.v-application {
      background: #EEEEEE;
      color: rgba(0, 0, 0, 0.87);
  }

  .overflow-y-hidden {
    overflow-y: hidden;
  }
</style>
