import Vue from 'vue'
// import App from './App.vue'
import AppLayout from '@/pages/layout/AppLayout.vue';
import router from '@/app/router/router'
import store from '@/app/store'
import './registerServiceWorker'
import vuetify from '@/app/plugins/vuetify';

import '@/app/bootstrap';

import App from '@/App.vue';

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  computed: {
    dark() {
      return this.$vuetify.theme.dark;
    }
  },
  render: h => h(App),
}).$mount('#app')
