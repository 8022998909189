<template>
  <div>

    <v-row>
      <v-col cols="12" md="6">
         <v-card>
            <v-card-title>
              Dados do Usuário
            </v-card-title>
            <v-divider></v-divider>

            <!-- <z-loading :loading="$store.state.loading" height="100px" color="primary"></z-loading> -->

            <v-form ref="form" v-model="form_valido" lazy-validation>
            <v-container grid-list-md>

              <!-- <v-layout row wrap>
                <h3 class="subheader">Usuário</h3>
              </v-layout> -->
              <v-layout row wrap>
                <v-flex sm12 class="py-0">
                  <v-text-field v-bind="{...forms}" :rules="regras.nome" label="Nome" v-model="usuario.name" autofocus :disabled="isAdmin"></v-text-field>
                </v-flex>
                <v-flex  sm12 class="py-0">
                  <v-text-field v-bind="{...forms}" :rules="regras.email" label="Login" v-model="usuario.email" :disabled="isAdmin || isEditing"></v-text-field>
                </v-flex>
                <v-flex sm12>
                  <v-select v-bind="{...forms}" :items="perfis" :rules="regras.perfil" label="Perfil" v-model="usuario.perfil_id" :disabled="isAdmin"></v-select>
                </v-flex>
              </v-layout>

              <!-- <v-layout row wrap>
                <h3 class="subheader">Senha</h3>
              </v-layout> -->
              <v-layout row wrap>
                <v-flex sm12 v-if="exibirSenhaAtual">
                    <v-text-field v-bind="{...forms}" :error-messages="erros.senha_atual" :rules1="regras.senha_atual" :type="exibir_senha_atual ? 'text' : 'password'" label="Senha Atual" v-model="usuario.senha_atual" @click:append="exibir_senha_atual = !exibir_senha_atual" :append-icon="exibir_senha_atual ? 'mdi-eye-off' : 'mdi-eye'"></v-text-field>
                </v-flex>
                <v-flex sm12>
                    <v-text-field v-bind="{...forms}" :error-messages="erros.senha" :rules1="regras.senha" :type="exibir_senha1 ? 'text' : 'password'" label="Senha" v-model="senha" @click:append="exibir_senha1 = !exibir_senha1" :append-icon="exibir_senha1 ? 'mdi-eye-off' : 'mdi-eye'"></v-text-field>
                </v-flex>
                <v-flex sm12>
                    <v-text-field v-bind="{...forms}" :error-messages="erros.confirmar_senha" :rules1="regras.confirmar_senha" :type="exibir_senha2 ? 'text' : 'password'" label="Confirmar Senha" v-model="confirmar_senha" @click:append="exibir_senha2 = !exibir_senha2" :append-icon="exibir_senha2 ? 'mdi-eye-off' : 'mdi-eye'"></v-text-field>
                </v-flex>
              </v-layout>

              <!-- <v-layout row wrap>
                <v-flex sm12>
                    <v-textarea label="Observações" placeholder="Observações sobre o  usuario" v-model="usuario.observacoes"></v-textarea>
                </v-flex>
              </v-layout> -->
            </v-container>
            </v-form>


            <v-divider></v-divider>
            <v-card-actions>
              <v-switch v-if="$route.meta.editando" class="my-0 shrink" color="primary" label="Ativo" v-model="usuario.ativo" hide-details :disabled="isAdmin"></v-switch>
              <v-spacer></v-spacer>
              <v-btn text @click="$router.go(-1)" :disabled="salvando">Cancelar</v-btn>
              <v-btn depressed color="primary" @click="confirmar" :loading="salvando">Salvar</v-btn>
            </v-card-actions>
          </v-card>
      </v-col>

      <v-col cols="12" md="6" v-if="false">
        <v-card>
          <v-card-title>
            Permissões
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text style="min-height: 400px">
            
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
export default {
    name: 'usuarios-form',
    data() {
        return {
          usuario: {
            perfil_id: 3,
            senha_atual: ''
          },
          salvando: false,
          regras: {},
          tab: '',
          senha: '',
          confirmar_senha: '',
          exibir_senha_atual: false,
          exibir_senha1: false,
          exibir_senha2: false,
          form_valido: false,
          erros: {
            senha_atual: '',
            senha: '',
            confirmar_senha: ''
          },
          regras: {
            nome: [v => !!v || 'O nome do usuário precisa ser preenchido.'],
            perfil: [v => !!v || 'O perfil precisa ser definido.'],
            email: [v => !!v || 'O login do usuário precisa ser preenchido.'],
            senha_atual: [v => !!v || 'A senha atual precisa se informada.'],
            senha: [v => !!v || 'A senha precisa ser preenchida.', v => (v && v.length >= 3) || 'A senha precisa ter o mínimo de 3 dígitos'],
            confirmar_senha: [
              v => !!v || 'Confirmar senha precisa ser preenchida.', 
              v => (v && v.length >= 3) || 'Confirmar senha precisa ter o mínimo de 3 dígitos',
              v => v == this.senha || 'As senhas não conferem'
            ]
          }
        };
    },

    beforeRouteLeave(to, from, next) {
        this.UNSET_USUARIO();
        next();
    },
    
    beforeMount () {
      this.$store.dispatch('listas/perfis');
    },
    
    async mounted() {
      this.setTitulo(this.titulo);
        if (this.$route.name == "UsuariosEditar") {
            if (!this.$store.state.usuarios.usuario) {
                if (this.$route.params.id) {
                    await this.abrir(this.$route.params.id);
                }
            }
            this.usuario = this.clone(this.$store.state.usuarios.usuario);
        }
    },

    methods: {
        ...mapMutations(["setTitulo"]),
        ...mapMutations("usuarios", ["UNSET_USUARIO"]),
        ...mapActions("usuarios", ["abrir", "atualizar", "salvar"]),

        async confirmar() {
          if (this.$refs.form.validate()) {
            try {
              this.salvando = true;
              
              if (!this.validarSenha()) return false;

              this.usuario.password = this.confirmar_senha;
              if (this.$route.params.id) {
                  await this.atualizar(this.usuario);
              } else {
                  await this.salvar(this.usuario);
              }
              
              this.$router.push({ path: "/sistema/usuarios" });
              this.$eventbus.notificar("Usuário salvo com sucesso");
            } catch(error) {
              this.$eventbus.erro('Não foi possível salvar este Usuário. Verifique os dados do usuario e tente novamente.', error);
            } finally {
               this.salvando = false;
            }
          }
        },

        validarSenha() {
          this.erros = {
            senha_atual: '',
            senha: '',
            confirmar_senha: ''
          }

          if (this.confirmar_senha || !this.$route.meta.editando) {
            if (this.usuario.name == 'Administrador') {
              if (!this.usuario.senha_atual) {
                this.erros.senha_atual = 'A senha atual precisa ser informada.';
                return false;
              }
            }

            if (!this.senha) {
              this.erros.senha = 'A senha precisa ser informada.';
              return false;
            }

            if (!this.confirmar_senha) {
              this.erros.confirmar_senha = 'A confirmação da senha precisa ser informada.';
              return false;
            }

            if (this.confirmar_senha != this.senha) {
              this.erros.confirmar_senha = 'A confirmação da senha não está correta.';
              return false;
            }
          }

          return true;
        }
    },

    computed: {
      ...mapState([
        'forms',
      ]),
      
      isAdmin(){
        if (this.usuario.name == 'Administrador') {
          return true
        }
        return false;
      },

      isEditing() {
        return this.$route.name == "UsuariosEditar";
      },

      titulo() {
        let titulo = this.$route.meta.editando ? 'Editar' : 'Adicionar';
        return titulo + ' ' + 'Usuário';
      },

      exibirSenhaAtual() {
        return this.$route.meta.editando && this.usuario.name == 'Administrador';
      },

      perfis() {
        const perfis = this.$store.getters['listas/perfis'];
        return perfis.filter(perfil => {
          if (!this.$auth.isAdmin()) {
            return perfil.text != 'Administrador';
          } else {
            return true;
          }
        });
      }
    },

};
</script>

<style scoped>
/* .container.grid-list-md .layout .flex{
    padding-top: 0px;
    padding-right: 4px;
    padding-bottom: 0px;
    padding-left: 4px;
} */
</style>
