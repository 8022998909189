<template>
  <div class="editor200 mb-3">
    <v-card flat color="transparent">
      <div class="d-flex caption mb-1">
        <span>{{label}}</span>
        <v-spacer></v-spacer>
        <slot name="info"></slot>
      </div>
      <v-card-text class="pa-0 text--primary">
        <ckeditor :class="light_dark" :editor="editor" :value="value" :config="editorConfig" @input="onInput"></ckeditor>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
  import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
  import '@ckeditor/ckeditor5-build-classic/build/translations/pt-br';

  let items_default = ["undo", "redo", "bold", "italic", "blockQuote", "ckfinder", "imageTextAlternative", "imageUpload", "heading", "imageStyle:full", "imageStyle:side", "link", "numberedList", "bulletedList", "mediaEmbed", "insertTable", "tableColumn", "tableRow", "mergeTableCells"];

  export default {
    name: 'z-rich-editor',
    
    props: {
      value: {
        type: String,
        default: ''
      },

      label: {
        type: String,
        default: ''
      },
    },

    data() {
      return {
        editor: ClassicEditor,
        editorData: '',
        editorConfig: {
          language: 'pt-br',
          toolbar: {
            items: [
              'undo',
              'redo',
              '|',
              'heading', 
              '|',
              'bold',
              'italic',
              'link',
              'bulletedList',
              'numberedList',
            ]
          }
        }
      }
    },

    mounted () {

    },

    methods: {
      onInput(valor) {
        this.$emit('input', valor);
      }
    },

    computed: {
      light_dark() {
        return {
          // 'black--text': !this.$root.dark,
          'theme--dark': this.$root.dark
        }
      }
    },

    // watch: {
    //   value: {
    //     immediate: true,
    //     handler(newValue, oldValue) {
    //       if (newValue == null) {
    //         this.editorData = '';
    //       } else {
    //         this.editorData = newValue;
    //       }
    //     }
    //   },
    // },
  }
</script>

<style scoped>
  .editor200 >>> .ck.ck-editor__editable_inline {
    min-height: 100px !important;
  }

  .editor200 >>> .ck-editor__editable {
    max-height: 100px;
  }

  .theme--dark >>> .ck.ck-editor__main>.ck-editor__editable {
    background: #3b3b3b;
    border-radius: 0;
  }

  .theme--dark >>> .ck.ck-toolbar {
    background: none;
    padding: 0 var(--ck-spacing-small);
    border: 1px solid var(--ck-color-toolbar-border);
  }

  .theme--dark >>> .ck.ck-reset_all, .ck.ck-reset_all * {
    border-collapse: collapse;
    font: normal normal normal var(--ck-font-size-base)/var(--ck-line-height-base) var(--ck-font-face);
    color: white !important; 
    text-align: left;
    white-space: nowrap;
    cursor: auto;
    float: none;
  }

  .theme--dark >>> .ck.ck-button.ck-off {
    color: white;
  }

  .theme--dark >>> .ck.ck-button.ck-on, a.ck.ck-button.ck-on {
    color: black;
    background: var(--ck-color-button-on-background);
  }
</style>