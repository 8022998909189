<template>
  <div>
    <div class="body-2 font-weight-bold mb-1">{{label}}</div>
    <v-card flat outlined class="pa-2" :min-height="minHeight" :class="{'grey lighten-3': !$root.dark}">
      <slot></slot>
    </v-card>
  </div>
</template>

<script>
  export default {
    name: 'z-card-texto',

    props: {
      label: {
        type: String,
        default: ''
      },
      minHeight: {
        type: String,
        default: '80px'
      }
    },
  }
</script>

<style scoped>

</style>