<template>
  <div>
    <v-container grid-list-md>

      <v-layout row wrap>
        <v-flex sm6>
          <v-card>
            <v-card-title>
              Perfis
            </v-card-title>
            <v-divider></v-divider>
            <div style="height: 400px; overflow-y: auto">
              <v-list>
                <template v-for="(perfil, index) in perfis">
                  
                  <v-list-item :class="{'selecionado primary--text': perfil_selecionado.descricao == perfil.descricao}" :key="perfil.descricao" @click="carregarPermissoes(perfil)">
                    <v-list-item-content>
                      <v-list-item-title v-html="perfil.descricao"></v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action v-if="perfil_selecionado.descricao == perfil.descricao">
                      <v-icon>mdi-arrow-right</v-icon>
                    </v-list-item-action>
                  </v-list-item>

                  <v-divider :key="index" v-if="index < perfis.length-1"></v-divider>
                </template>
              </v-list>
            </div>
            <v-card-actions>
              <v-btn icon @click="adicionar"><v-icon>mdi-plus</v-icon></v-btn>
              <v-btn icon @click="editar" :disabled="isAdmin"><v-icon>mdi-pencil</v-icon></v-btn>
              <!-- <v-btn icon @click="$refs.dialogoExcluirPerfil.abrir()" :disabled="isAdmin"><v-icon>mdi-delete</v-icon></v-btn> -->
            </v-card-actions>
          </v-card>
        </v-flex>

        <v-flex sm6>
          <v-card>
            <v-card-title>
              Permissões
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text style="height: 400px; overflow-y: auto">
              <v-treeview dense v-model="selecionados" :items="permissoes" selected-color="primary" :open.sync="abertos" :open-all="abrirtodos" activatable hoverable selectable open-on-click transition></v-treeview>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <!-- <v-btn text :disabled="loading || isAdmin">Cancelar</v-btn> -->
              <v-btn depressed color="primary" @click="salvarPermissoes" :disabled="isAdmin" :loading="loading">Salvar</v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>

    </v-container>

    <z-dialogo ref="dialogoPerfil" titulo="Perfil" dividers>

      <template v-slot:texto>
        <v-text-field v-bind="{...forms}" ref="descricao" label="Descrição" v-model="p.descricao" placeholder=" " autofocus></v-text-field>
      </template>

      <v-btn text :disabled="salvando" @click="$refs.dialogoPerfil.fechar()">Cancelar</v-btn>
      <v-btn depressed color="primary" @click="salvar" :loading="salvando">Salvar</v-btn>
    </z-dialogo>

    <z-dialogo ref="dialogoExcluirPerfil" titulo="Excluir perfil" dividers>

      <v-container slot="texto">
        <h3 class="mb-2">Deseja excluir o perfil selecionado?</h3>
        <span class="subtitle-2 text--primary">{{ perfil_selecionado.descricao }}</span>
      </v-container>

      <v-btn text :disabled="excluindo" @click="$refs.dialogoExcluirPerfil.fechar()">Cancelar</v-btn>
      <v-btn depressed color="red" dark @click="confirmarExcluir" :loading="excluindo">Excluir</v-btn>
    </z-dialogo>
  </div>
</template>

<script>
  import {mapState, mapActions, mapMutations} from 'vuex';
  import permissoes from './permissoes-text';
    export default {
        name: "usuarios-permissoes",
        data() {
          return {
            busca: '',
            loading: false,
            excluindo: false,
            salvando: false,
            permissoes: permissoes,
            selecionados: [],
            abertos: ['todas'],
            abrirtodos: false,
            ativo: null,
            perfil_selecionado: {
              descricao: '',
              permissoes: ''
            },
            p: {
              descricao: '',
              permissoes: ''
            }
          };
        },

        async mounted() {
          this.$store.commit("setTitulo", "Permissões");
          await this.listarPerfis();
          this.carregarPermissoes(this.perfis[0]);
        },

        methods: {
          ...mapActions({
            listarPerfis: 'usuarios/perfis',
            atualizarPerfil: 'usuarios/atualizarPerfil',
            salvarPerfil: 'usuarios/salvarPerfil',
            excluirPerfil: 'usuarios/excluirPerfil'
          }),

          carregarPermissoes(perfil){
            this.perfil_selecionado = perfil;
            this.selecionados = [];
            
            if (perfil.permissoes)
              this.selecionados = perfil.permissoes.split(',');
          },

          adicionar() {
            this.p = {
              descricao: '',
              permissoes: ''
            }
            this.$refs.dialogoPerfil.abrir();
            this.setFocus('descricao');
          },

          editar() {
            this.p = this.clone(this.perfil_selecionado);
            this.$refs.dialogoPerfil.abrir();
          },

          async salvar() {
            try {
              this.salvando = true;
              if (!this.p.id) {
                let perfil = await this.salvarPerfil(this.p);
                this.selecionados = [];
                this.perfil_selecionado = perfil;
              } else {
                await this.atualizarPerfil(this.p);
              }

              this.$refs.dialogoPerfil.fechar();
            } catch (error) {
              this.$eventbus.error('Não foi possível salvar Perfil', error);
            } finally {
              this.salvando = false;
            }
          },


          async salvarPermissoes() {
            let perfil = this.perfis.find(p => p.id == this.perfil_selecionado.id);
            if (perfil) {
              perfil.permissoes = this.selecionados.join(',');
              try {
                this.loading = true;
                await this.atualizarPerfil(perfil);
              } catch (error) {
                this.$eventbus.error('Não foi possível salvar Permissões', error);
              } finally {
                this.loading = false;
              }
            }
          },

          async confirmarExcluir() {
              try {
                this.excluindo = true;
                await this.excluirPerfil(this.perfil_selecionado);
                this.$refs.dialogoExcluirPerfil.fechar();
                this.selecionados = [];
              } catch (error) {
                this.$eventbus.error('Não foi possível excluir Perfil', error);
              } finally {
                this.excluindo = false;
              }

          }

        },

        computed: {
          ...mapState({
            forms: state => state.forms
          }),

          isAdmin() {
            return this.perfil_selecionado.descricao == 'Administrador'
          },

          perfis() {
            return this.$store.state.usuarios.perfis;
          }
        }
    };

</script>

<style scoped>

.selecionado {
  background: rgba(0,0,0,0.04);
}
</style>
