import Vue from "vue"
import { globals } from '@/app/globals';

Vue.filter("status", (status) => {
  if (status) {
    let s = globals.STATUS_CASO_TESTE.find(s => s.value == status);
    return s.text;
  }

  return '';
}

);

Vue.filter("prioridade", (prioridade) => {
  if (prioridade) {
    let p = globals.PRIORIDADES_CASO_TESTE.find(s => s.value == prioridade);
    return p.text;
  }

  return '';
})

Vue.filter("tipoRequisito", (tipo) => {
  if (tipo) {
    let p = globals.TIPOS_REQUISITO.find(s => s.value == tipo);
    return p.text;
  }

  return '';
})

Vue.filter("statusRequisito", (status) => {
  if (status) {
    let p = globals.STATUS_REQUISITO.find(s => s.value == status);
    return p.text;
  }

  return '';
})

Vue.filter("valorBR_", (valor, decimais = 2) => {
  return (valor).toLocaleString('pt-BR', {maximumFractionDigits: decimais});
})