// import especificacoesApi from "@/app/services/especificacoes";
import axios from '@/app/plugins/Axios';

export default {
    namespaced: true,
    state: {
      especificacao: {},
      especificacoes: [],
    },

    getters: {

    },

    mutations: {
      LISTAR(state, especificacoes) {
        state.especificacoes = especificacoes.map(especificacao => {
          return {
            ...especificacao,
            ativo: false
          }
        });
      },

      SET_ESPECIFICACAO(state, especificacao) {
        state.especificacao = especificacao;
      },

      SALVAR(state, especificacao) {
        state.especificacoes.push(especificacao);
        state.especificacao = especificacao;
      },

      ATUALIZAR(state, especificacao) {
        let index = state.especificacoes.findIndex(especificacao_ => especificacao_.id == especificacao.id);
        state.especificacoes[index].titulo = especificacao.titulo;
        state.especificacoes[index].chave = especificacao.chave;
      },

      EXCLUIR(state, especificacao) {
        let index = state.especificacoes.findIndex(especificacao_ => especificacao_.id == especificacao.id);
        state.especificacoes.splice(index, 1);
      }
    },

    actions: {
      async listar({commit}, projeto_id) {
        let response = await axios.get('projetos/'+projeto_id+'/especificacoes');
        commit('LISTAR', response.data);
      },

      async abrir({commit}, especificacao_id) {
        let response = await axios.get('especificacoes/' + especificacao_id);
        commit('SET_ESPECIFICACAO', response.data);
      },

      async buscar({commit}, search) {
        let response = await axios.get('especificacoes/buscar?busca=' + search);
        commit('LISTAR', response.data);
      },

      async salvar({commit}, especificacao) {
        let response = await axios.post('especificacoes', especificacao);
        commit('SALVAR', response.data);
        return response.data;
      },

      async atualizar({commit}, especificacao) {
        let response = await axios.put('especificacoes/' + especificacao.id, especificacao);
        commit('ATUALIZAR', response.data);
      },

      async excluir({commit}, especificacao) {
        await axios.delete('especificacoes/' + especificacao.id);
        commit('EXCLUIR', especificacao);
      }
    }
}
