<template>

    <v-dialog ref="dialog" :return-value.sync="dateFormatted" persistent v-model="exibir" lazy full-width width="290px">
      <v-text-field slot="activator" :flat="isToBeFlat" :class="{'transparente': transparente && isToBeFlat}" ref="campo" v-bind="$attrs" v-model="dateFormatted" :readonly="readonly || isSm" :disabled="disabled" @input="onInput" @focus="onFocus" @blur="onBlur" @keyup="menu = false" @keypress.esc="dateFormatted = ''">
        <v-icon slot="prepend-inner" :disabled="disabled || readonly">event</v-icon>
      </v-text-field>
        <v-date-picker v-model="date" scrollable locale="pt-BR">
            <v-btn flat icon @click="setHoje">
              <v-icon>event</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn flat @click="exibir = false">Cancelar</v-btn>
            <v-btn flat color="primary" @click="$refs.dialog.save(dateFormatted)">OK</v-btn>
        </v-date-picker>
    </v-dialog>

</template>

<script>

export default {
    name: 'z-date-picker',

    props: {
      value: {
        type: [String],
        default: null
      },
      transparente: {
        type: Boolean,
        default: false
      },
      raiseOnFocus: Boolean,
      flat: Boolean,
      readonly: Boolean,
      disabled: Boolean,
      min: String
    },

    data() {
        return {
          dateFormatted: null,
          menu: false,
          isFocused: false,
          exibir: false
        }
    },

    computed: {
      computedDateFormatted () {
        return this.dataBR(this.date)
      },

      date: {
        get () {
          this.dateFormatted = this.dataBR(this.value);
          return this.value;
        },
        set (val) {
          // this.dateFormatted = this.dataBR(val);
          this.$emit('input', val);
        }
      },

      isToBeFlat() {
        if (this.flat && this.raiseOnFocus && this.isFocused) {
          return false;
        } else {
          return this.flat;
        }
      },

      isSm() {
        return this.$vuetify.breakpoint.smAndDown;
      }
    },

    methods: {
      onInput() {
        if (this.dateFormatted) {
          if (this.dateFormatted.length == 2) {
              this.dateFormatted = this.dateFormatted + '/' + (new Date().getMonth() + 1).toString().padStart(2, '0');
              this.selecionarMes();
          } else if (this.dateFormatted.length == 5) {
              this.dateFormatted = this.dateFormatted + '/' + new Date().getFullYear();
              this.selecionarAno();
          }

          if (this.dateFormatted.length == 10) {
              this.$emit('input', this.dataUS(this.dateFormatted));
          }
        }
      },

      onFocus(e) {
        if (!this.isSm) {
          e.target.selectionStart = 0;
          e.target.selectionEnd   = e.target.value.length;
          this.isFocused = true;
        }
      },

      onBlur() {
        if (this.dateFormatted) {
          if (this.dateFormatted.length == 10) {
            this.date = this.dataUS(this.dateFormatted); 
          } else if (this.dateFormatted.length > 0 && this.dateFormatted.length < 10 && this.date != '') {
           this.dateFormatted = this.dataBR(this.date);
          } else {
            this.date = this.dateFormatted = '';
          }
        } else {
          this.date = this.dateFormatted;
        }

        this.isFocused = false;
      },

      onChange() {
        this.exibir = false;
        this.$emit('change', this.data);
      },

      selecionarAno() {
        setTimeout(() => {
          this.$refs.campo.$el.querySelector('input').selectionStart = 6;
          this.$refs.campo.$el.querySelector('input').selectionEnd   = 10;
        }, 0);
      },

      selecionarMes() {
        setTimeout(() => {
          this.$refs.campo.$el.querySelector('input').selectionStart = 3;
          this.$refs.campo.$el.querySelector('input').selectionEnd   = 5;
        }, 0);
      },

      dataBR (date) {
        if (!date) return null

        if (date.length > 10) {
          date = date.substr(0,10);
        }

        const [year, month, day] = date.split(/[-/]/);
        return `${day}/${month}/${year}`;
      },

      dataUS (date) {
        if (!date) return null

        const [year, month, day] = date.split(/[-/]/).reverse();
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      },

      setHoje() {
        this.date = this.hoje();
      }
    }

}

</script>

<style scoped>

</style>
