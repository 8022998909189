// import planosdetesteApi from "@/app/services/planosdeteste";
import axios from '@/app/plugins/Axios';

export default {
    namespaced: true,
    state: {
      planodeteste: {},
      planosdeteste: [],
      suitesdeteste: [],
      casosselecionados: [],
      casosdeteste: [],
      planodeteste_atual: {
        nome: ''
      },
    },

    getters: {

    },

    mutations: {
      LISTAR(state, planosdeteste) {
        state.planosdeteste = planosdeteste;
      },

      LISTAR_SUITESDETESTE(state, suitesdeteste) {
        state.suitesdeteste = suitesdeteste;
      },

      SALVAR(state, planodeteste) {
        state.planosdeteste.push(planodeteste);
      },

      ATUALIZAR(state, planodeteste) {
        let index = state.planosdeteste.findIndex(planodeteste_ => planodeteste_.id == planodeteste.id);
        state.planosdeteste[index] = planodeteste;
      },

      EXCLUIR(state, planodeteste) {
        let index = state.planosdeteste.findIndex(planodeteste_ => planodeteste_.id == planodeteste.id);
        state.planosdeteste.splice(index, 1);
      },

      SET_PLANODETESTE(state, planodeteste) {
        state.planodeteste = planodeteste;
      },

      SET_CASOSDETESTE(state, casosdeteste) {
        state.casosdeteste = casosdeteste;
      }
    },

    actions: {
      async listar({commit}, projeto_id) {
        let response = await axios.get('planosdeteste/listar/' + projeto_id);
        commit('LISTAR', response.data);
      },

      async abrir({commit}, planodeteste_id) {
        let response = await axios.get('planosdeteste/' + planodeteste_id);
        commit('SET_PLANODETESTE', response.data);
      },

      async buscar({commit}, request) {
        let response = await axios.get('planosdeteste/buscar/' + request.projeto_id, { params: {busca: request.search}});
        commit('LISTAR', response.data);
      },

      async salvar({commit}, planodeteste) {
        let response = await axios.post('planosdeteste', planodeteste);
        commit('SALVAR', response.data);
      },

      async atualizar({commit}, planodeteste) {
        let response = await axios.put('planosdeteste/' + planodeteste.id, planodeteste);
        commit('ATUALIZAR', response.data);
      },

      async excluir({commit}, planodeteste) {
        await axios.delete('planosdeteste/' + planodeteste.id);
        commit('EXCLUIR', planodeteste);
      },

      async listarSuitesDeTeste({commit}, request) {
        let response = await axios.get('planosdeteste/' + request.plano_id + '/suitesdeteste/' + request.projeto_id);
        commit('LISTAR_SUITESDETESTE', response.data.suitesdeteste);
        return response.data;
      },

      async listarCasosDeTeste({commit}, request) {
        let casosdeteste = await axios.get('planosdeteste/' + request.plano_id + '/casosdeteste?ordem='+request.ordem + '&release_id='+request.release_id).then(response => response.data);
        commit('SET_CASOSDETESTE', casosdeteste);
        return casosdeteste;
      },

      async calcularTCW({dispatch}, plano_id) {
        await axios.get('planosdeteste/' + plano_id + '/calculartcw').then(response => response.data);
        dispatch('listarCasosDeTeste', { plano_id: plano_id, ordem: 'tcw' });
      },

      async ordenarAleatoriamente({dispatch}, request) {
        await axios.get('planosdeteste/' + request.plano_id + '/ordenaraleatoriamente').then(response => response.data);
        dispatch('listarCasosDeTeste', { plano_id: request.plano_id, release_id: request.release_id, ordem: 'aleatorio' });
      },

      async casosDeTesteSelecionados({commit}, plano_id) {
        let response = await axios.get('planosdeteste/' + plano_id + '/casosselecionados');
        return response.data;
        // commit('LISTAR_SUITESDETESTE', response.data);
      },

      async selecionarCasosDeTeste({commit}, obj) {
        let response = await axios.post('planosdeteste/' + obj.plano_id + '/selecionarcasosdeteste', obj.selecao);
        // commit('LISTAR_SUITESDETESTE', response.data);
      },

      async salvarItemSelecionado({commit}, obj) {
        let response = await axios.post('planosdeteste/' + obj.plano_id + '/salvaritemselecionado', obj.item);
        // commit('LISTAR_SUITESDETESTE', response.data);
      },
    }
}
