// import requisitosApi from "@/app/services/requisitos";
import axios from '@/app/plugins/Axios';

export default {
    namespaced: true,
    state: {
      plano: {},
      release: {},
      caso: {},
      passos: [],
      metricas_suite: [],
      suites: [],
      planos: [],
      releases: [],
      semplanos: false,
      semreleases: false,

    },

    getters: {},

    mutations: {
      SET_PLANO_RELEASE(state, payload) {
        state.plano   = payload.plano;
        state.release = payload.release;
      },

      SET_PLANO(state, plano) {
        state.plano = plano;
      },

      SET_RELEASE(state, release) {
        state.release = release;
      },
      
      SET_PLANOS(state, planos) {
        state.planos = planos;
      },

      SET_RELEASES(state, releases) {
        state.releases = releases;
      },

      SET_SUITES(state, suites) {
        state.suites = suites;
      },

      SET_CASO(state, caso) {
        state.caso = caso;
        state.passo = caso.passos;
      },

      SET_METRICAS_SUITES(state, metricas_suite) {
        state.metricas_suite = metricas_suite;
      },

      ATUALIZAR_CASODETESTE(state, execucao) {
        let suite = state.suites.find(suite => suite.id == state.caso.suite_id);
        let caso = suite.casos.find(caso => caso.id == state.caso.id);
        caso.status = execucao.status;
      },

      SET_SEMPLANOS(state, semplanos) {
        state.semplanos = semplanos;
      },

      SET_SEMRELEASES(state, semreleases) {
        state.semreleases = semreleases;
      }
    },

    actions:{
      async carregarPlanosDeTeste({commit}, projeto_id) {
        let planos = await axios.get('projeto/'+ projeto_id + '/executartestes/planos').then(response => response.data);
        commit('SET_PLANOS', planos);
      },

      async carregarPlanoRelease({commit}, request) {
        let response = await axios.get('executartestes/carregarplanorelease', {
          params: {
            plano_id: request.plano_id,
            release_id: request.release_id
          }
        }).then(response => response.data);

        commit('SET_PLANO_RELEASE', response);
      },

      async carregarSuitesDeTeste({commit}, request) {
        let suites = await axios.get('projeto/'+ request.projeto_id +'/executartestes/'+ request.plano_id +'/release/' + request.release_id).then(response => response.data);
        commit('SET_SUITES', suites);
      },

      async carregarCasoDeTeste({commit}, {caso_id, release_id}) {
        let response = await axios.get('executartestes/caso/'+caso_id, {params: {release_id}}).then(response => response.data);
        commit('SET_CASO', response);
      },

      async metricasSuitesDeTeste({commit}, plano_id) {
        let response = await axios.get('metricas/planodeteste/'+ plano_id +'/suitesdeteste').then(response => response.data);
        commit('SET_METRICAS_SUITES', response);
      }
    },
}
