<template>

  <v-menu v-model="menu" :close-on-content-click="false" transition="slide-y-transition" offset-y>
    
    <template v-slot:activator="{ on }">
      <v-btn text v-on="on" :ripple="false" v-show="projetos.length > 0">
        <template v-if="projeto_atual.id == null">
          Selecione um Projeto
        </template>
        <template v-else>
          {{projeto_atual.prefixo}} - {{projeto_atual.nome}}
        </template>
        <v-icon right>mdi-chevron-down</v-icon>
      </v-btn>
    </template>

    <v-card flat class="overflow-hidden">

      <v-app-bar absolute elevate-on-scroll scroll-target="#scrolling-techniques-7">
        <v-text-field dense ref="busca" v-model="busca" flat :solo="!isDark" :solo-inverted="isDark" hide-details label="Buscar Projeto" prepend-inner-icon="mdi-magnify"></v-text-field>
        <v-btn icon @click="fechar">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>

      <v-sheet id="scrolling-techniques-7" class="overflow-y-auto custom-scroll" height="400" width="500" style="margin-top: 64px">
        <z-loading v-if="loading" :loading="loading" height="400px"></z-loading>

        <template v-else>
          <v-divider></v-divider>
          <v-list>
            <v-subheader>Projetos</v-subheader>
          <v-divider></v-divider>
            <template v-for="(projeto, index) in projetos_filtro">
              <v-list-item :key="projeto.prefixo" @click="selecionarProjeto(projeto)">
                <v-list-item-content>
                  <v-list-item-title>{{projeto.prefixo}} - {{ projeto.nome }}</v-list-item-title>
                  <!-- <v-list-item-subtitle class="text-truncate"><span v-html="projeto.descricao"></span></v-list-item-subtitle> -->
                </v-list-item-content>
                <v-list-item-action>
                  <v-chip small color="primary" v-if="projeto.id == projeto_atual.id">Atual</v-chip>
                </v-list-item-action>
              </v-list-item>
              <v-divider v-if="index < projetos_filtro.length - 1" :key="index"></v-divider>
            </template>
          </v-list>
        </template>
      </v-sheet>

    </v-card>
  </v-menu>
</template>

<script>

  import storage from "@/app/utils/LocalStorage";
  export default {
    name: 'escolher-projeto',
    data: () => ({
      menu: false,
      busca: '',
      loading: false,
    }),

    mounted () {
      this.listarProjetos();
    },

    methods: {
      fechar() {
        this.menu = false;
        this.busca = '';
        this.$emit('onFechar');
      },

      selecionarProjeto(projeto) {
        storage.set('projeto', projeto);
        this.$store.commit('projetos/PROJETO_ATUAL', projeto);
        this.$store.commit('setLeftDrawerMenu', 'MenuInicial');
        
        this.$store.commit('casosdeteste/LISTAR_SUITES', []);
        this.$store.commit('casosdeteste/LISTAR_CASOS', []);

        this.$store.commit('especificacoes/LISTAR', []);
        this.$store.commit('requisitos/LISTAR', []);

        if (this.$route.name != 'projeto_visao_geral')
          this.$router.push({ name: 'projeto_visao_geral', params: {projeto_id: projeto.id}});
        this.fechar();
      },

      async listarProjetos() {
        try {
          this.loading = true;
          await this.$store.dispatch('projetos/listar_ativos');
        } catch (error) {
          this.$eventbus.erroSistema('Não foi possível listar os projetos.' + error);
        } finally {
          this.loading = false;
        }
      },

      replaceSpecialChars(str) {
        str = str.toLowerCase();
        str = str.replace(/[áàãäâ]/gi,"a");
        str = str.replace(/[éèëê]/gi,"e");
        str = str.replace(/[íìïî]/gi,"i");
        str = str.replace(/[óòõöô]/gi,"o");
        str = str.replace(/[úùüû]/gi,"u");
        str = str.replace(/[ç]/gi,"c");
        return str;
      }
    },

    computed: {
      projetos_filtro() {
        let busca = this.replaceSpecialChars(this.busca);

        if (this.busca.length > 0) {
          
          return this.projetos.filter(projeto => {
            let prefixo   = this.replaceSpecialChars(projeto.prefixo);
            let nome      = this.replaceSpecialChars(projeto.nome);
            let descricao = this.replaceSpecialChars(projeto.descricao);

            return prefixo.indexOf(busca) > -1 || nome.indexOf(busca) > -1 || descricao.indexOf(busca) > -1;
          });

        } else {
          return this.projetos;
        }
      },

      projetos() {
        return this.$store.state.projetos.projetos_ativos;
      },

      projeto_atual() {
        return this.$store.state.projetos.projeto_atual;
      },

      loading_global() {
        return this.$store.state.loading;
      },

      isDark() {
        return this.$vuetify.theme.dark;
      },

    },

    watch: {
      menu(newValue, oldValue) {
        if (newValue == true) {
          this.listarProjetos();

          setTimeout(() => {
            this.$refs.busca.focus();
          }, 300);
        }
      }
    },
  }
</script>

<style lang="scss" scoped>

</style>