export default [
  { 
    id: 'todas',
    name: 'Todas',
    children: [
      {
        id: 'projetos', name: 'Projetos de Teste',
        // children: [
        //   {id: 'projetos_acessar', name: 'Acessar'},
        //   {id: 'projetos_adicionar', name: 'Adicionar Locação'},
        //   {id: 'projetos_editar', name: 'Editar Locação'},
        //   {id: 'projetos_excluir', name: 'Excluir Locação'},
        //   {id: 'projetos_imprimir', name: 'Imprimir Locação'},
        // ]
      },
      {
        id: 'requisitos', name: 'Requisitos do Sistema',
        // children: [
        //   {id: 'requisitos_acessar', name: 'Acessar'},
        //   {id: 'requisitos_adicionar', name: 'Adicionar Vistoria'},
        //   {id: 'requisitos_editar', name: 'Editar Vistoria'},
        //   {id: 'requisitos_excluir', name: 'Excluir Vistoria'},
        //   {id: 'requisitos_imprimir', name: 'Imprimir Vistoria'},
        // ]
      },
      {
        id: 'suites', name: 'Suites de Teste',
        // children: [
        //   {id: 'suites_acessar', name: 'Acessar'},
        //   {id: 'suites_fazer_lancamento', name: 'Fazer Lançamento'},
        //   {id: 'suites_excluir', name: 'Excluir Lançamento'},
        //   {id: 'suites_fazer_sangria', name: 'Fazer Sangria'},
        //   {id: 'suites_imprimir', name: 'Imprimir Caixa'},
        // ]
      },
      {
        id: 'planos', name: 'Planos de Teste',
        // children: [
        //   {id: 'planos_acessar', name: 'Acessar'},
        //   {id: 'planos_fazer_lancamento', name: 'Fazer Lançamento'},
        //   {id: 'planos_excluir', name: 'Excluir Lançamento'},
        //   {id: 'planos_fazer_sangria', name: 'Fazer Sangria'},
        //   {id: 'planos_imprimir', name: 'Imprimir Caixa'},
        // ]
      },
      {
        id: 'executar_testes', name: 'Executar de Testes',
        // children: [
        //   {id: 'executar_acessar', name: 'Acessar'},
        //   {id: 'executar_fazer_lancamento', name: 'Fazer Lançamento'},
        //   {id: 'executar_excluir', name: 'Excluir Lançamento'},
        //   {id: 'executar_fazer_sangria', name: 'Fazer Sangria'},
        //   {id: 'executar_imprimir', name: 'Imprimir Caixa'},
        // ]
      },
      {
        id: 'sistema', name: 'Sistema',
        children: [
          { id: 'usuarios', name: 'Usuários' },
          { id: 'permissoes', name: 'Permissões' }
        ]
      },

    ]
  }
]
