<template>
  <div id="app">
    <div :id="uuid" ref="container_echarts" style="min-height: 400px; width: 100%"></div>
  </div>
</template>



<script>

import * as echarts from 'echarts';
import {v4 as uuidv4} from 'uuid';


export default {
  name: 'PieChart',

  created() {
    this.uuid = uuidv4();
  },

  mounted () {
    this.init();
  },

  umounted() {
    window.removeEventListener('resize', this.resize);
  },

  props: {
    dados: {
      type: Array,
      default: () => {
        return [];
      }
    },
    selecionados: {
      type: Object,
       default: () => {
        return {};
      }
    },
  },


  data() {
    return {
      uuid: null,
      myChart: {},
      options: {
        tooltip : {
          trigger: 'item',
          formatter: "{b} ({d}%)"
        },
        legend: {
          show: true,
          type: 'scroll',
          orient: 'horizontal',
          bottom: 20,
          x: 'center',
          selected: this.selecionados,
          icon: 'circle',
        },
        series : [
          {
            name: '',
            type: 'pie',
            radius : '60%',
            center: ['50%', '50%'],
            // roseType: 'area',
            label: {
              normal: {
                show: true,
                formatter: "({d}%)",
                fontSize: 12,
              },
            },
            data: this.dados,
            itemStyle: {
              borderRadius: 8,
              emphasis: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      }
    }
  },

  methods: {
    init() {
      this.myChart = echarts.init(document.getElementById(this.uuid));

      this.myChart.setOption(this.options, true);

      window.addEventListener('resize', this.resize);
    },

    resize() {
      this.myChart.resize();
    },

  },

  watch: {
    dados(value, oldvalue) {
      this.options.series[0].data = value;
      this.options.legend.selected = this.selecionados;
      this.myChart.setOption(this.options, true);
    }
  },
}
</script>

<style>
</style>