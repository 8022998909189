import axios from 'axios';
import createCookie from '@/app/plugins/CookieCore';
import config from '@/app/config';

const cookie = createCookie();

function createAxios() {

  const options = {
    baseURL: config.API_URL,
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      'Accept': 'application/json',
      // 'Content-Type': 'application/json'
    },
    // withCredentials: true,
    // withXSRFToken: true,
  }

  let api_token = cookie.getCookie('api_token');

  function create() {

    const instance = axios.create(options);

    instance.interceptors.request.use(
      axiosConfig => {
        if (api_token) {
          axiosConfig.headers.Authorization = 'Bearer ' + api_token;
        }
        return axiosConfig;
      }, 
      error => Promise.reject(error)
    );

    instance.interceptors.response.use(
      response => response, 
      error => Promise.reject(error)
    );

    return instance;
  }

  return {
    create
  }

}

createAxios.install = function(Vue, options = {}) {
  Vue.prototype.$axios = createAxios().create();
}

export default createAxios;