import BemVindo from '@/pages/BemVindo.vue'

export default [

  {
    path: '/',
    name: 'home',
    component: BemVindo,
    meta: {layout: 'principal'}
  },

  /**
   * PROJETOS
   */
  {
    path: '/projetos',
    name: 'projetos',
    component: () => import(/* webpackChunkName: "projetos" */ '@/pages/projetos/Projetos.vue'),
    meta: {layout: 'principal', permissao: 'projetos'}
  },

  {
    path: '/projetos/adicionar',
    name: 'projeto_adicionar',
    component: () => import(/* webpackChunkName: "projetos" */ '@/pages/projetos/ProjetosForm.vue'),
    meta: {layout: 'principal', permissao: 'projetos'}
  },

  {
    path: '/projetos/:id/editar',
    name: 'projeto_editar',
    component: () => import(/* webpackChunkName: "projetos" */ '@/pages/projetos/ProjetosForm.vue'),
    meta: {
      editando: true,
      layout: 'principal', permissao: 'projetos'
    }
  },

  {
    path: '/projetos/:id/membros',
    name: 'ProjetoMembros',
    component: () => import(/* webpackChunkName: "projetos" */ '@/pages/projetos/ProjetosForm.vue'),
    meta: {
      editando: true,
      layout: 'principal', permissao: 'projetos'
    }
  },

  {
    path: '/projeto/visaogeral',
    name: 'projeto_visao_geral',
    component: () => import(/* webpackChunkName: "projetos" */ '@/pages/projetos/VisaoGeral.vue'),
    meta: {layout: 'principal', permissao: 'projetos'}
  },

  // /**
  //  * ESPECIFICAÇÕES E REQUISITOS
  //  */
  // {
  //   path: '/especificacoes',
  //   name: 'especificacoes',
  //   component: () => import(/* webpackChunkName: "projetos" */ '@/pages/requisitos/Especificacoes.vue'),
  //   meta: {layout: 'principal', permissao: 'requisitos'}
  // },

  // {
  //   path: '/especificacoes/adicionar',
  //   name: 'especificacoes_adicionar',
  //   component: () => import(/* webpackChunkName: "projetos" */ '@/pages/requisitos/EspecificacaoForm.vue'),
  //   meta: {layout: 'principal', permissao: 'requisitos'}
  // },

  // {
  //   path: '/especificacoes/:especificacao_id/editar',
  //   name: 'especificacoes_editar',
  //   component: () => import(/* webpackChunkName: "projetos" */ '@/pages/requisitos/EspecificacaoForm.vue'),
  //   meta: {editando: true, layout: 'principal', permissao: 'requisitos'}
  // },

  // {
  //   path: '/especificacoes/:especificacao_id/requisitos',
  //   name: 'requisitos',
  //   component: () => import(/* webpackChunkName: "projetos" */ '@/pages/requisitos/Requisitos.vue'),
  //   meta: {layout: 'principal', permissao: 'requisitos'}
  // },
  
  // {
  //   path: '/especificacoes/:especificacao_id/requisitos/adicionar',
  //   name: 'requisitos_adicionar',
  //   component: () => import(/* webpackChunkName: "requisitos" */ '@/pages/requisitos/RequisitoForm.vue'),
  //   meta: {layout: 'principal', permissao: 'requisitos'}
  // },

  // {
  //   path: '/especificacoes/:especificacao_id/requisitos/:requisito_id/editar',
  //   name: 'requisitos_editar',
  //   component: () => import(/* webpackChunkName: "requisitos" */ '@/pages/requisitos/RequisitoForm.vue'),
  //   meta: {editando: true, layout: 'principal', permissao: 'requisitos'}
  // },

  // {
  //   path: '/especificacoes/:especificacao_id/requisitos/:requisito_id/visualizar',
  //   name: 'requisitos_visualizar',
  //   component: () => import(/* webpackChunkName: "requisito_visualizar" */ '@/pages/requisitos/RequisitoVisualizar.vue'),
  //   meta: {layout: 'principal', permissao: 'requisitos'}
  // },


  /**
   * SUITES DE TESTE E CASOS DE TESTE
   */
  {
    path: '/projeto/suitesdeteste',
    name: 'projeto_casosdeteste',
    component: () => import(/* webpackChunkName: "projetos" */ '@/pages/casosdeteste/SuitesDeTeste.vue'),
    meta: {layout: 'principal', permissao: 'suites'}
  },
  {
    path: '/projeto/suitesdeteste/tabela',
    name: 'casosdeteste_tabela',
    component: () => import(/* webpackChunkName: "projetos" */ '@/pages/casosdeteste/TabelaDeTestes.vue'),
    meta: {layout: 'principal', permissao: 'suites'}
  },

  {
    path: '/projeto/suitesdeteste/adicionar',
    name: 'projeto_suitedeteste_adicionar',
    component: () => import(/* webpackChunkName: "projetos" */ '@/pages/casosdeteste/SuiteForm.vue'),
    meta: {layout: 'principal', permissao: 'suites'}
  },

  {
    path: '/projeto/suitesdeteste/:suite_id/editar',
    name: 'projeto_casosdeteste_suite_editar',
    component: () => import(/* webpackChunkName: "projetos" */ '@/pages/casosdeteste/SuiteForm.vue'),
    meta: {editando: true, layout: 'principal', permissao: 'suites'}
  },

  {
    path: '/projeto/suitesdeteste/:suite_id',
    name: 'projeto_casosdeteste_suite_casos_de_teste',
    component: () => import(/* webpackChunkName: "projeto_casosdeteste_suite_casos_de_teste" */ '@/pages/casosdeteste/CasosLista.vue'),
    meta: {layout: 'principal', permissao: 'suites'}
  },

  {
    path: '/projeto/suitesdeteste/:suite_id/casos/adicionar',
    name: 'projeto_casosdeteste_caso_adicionar',
    component: () => import(/* webpackChunkName: "projeto_casosdeteste_caso_adicionar" */ '@/pages/casosdeteste/CasoForm.vue'),
    meta: {layout: 'principal', permissao: 'suites'}
  },

  {
    path: '/projeto/suitesdeteste/:suite_id/casos/:caso_id/editar',
    name: 'projeto_casosdeteste_caso_editar',
    component: () => import(/* webpackChunkName: "projeto_casosdeteste_caso_editar" */ '@/pages/casosdeteste/CasoForm.vue'),
    meta: {editando: true, layout: 'principal', permissao: 'suites'}
  },

  {
    path: '/projeto/suitesdeteste/:suite_id/casos/:caso_id/passos',
    name: 'projeto_casosdeteste_caso_passos',
    component: () => import(/* webpackChunkName: "projeto_casosdeteste_caso_passos" */ '@/pages/casosdeteste/CasoVisualizar.vue'),
    meta: {modo: 'visualizar', layout: 'principal', permissao: 'suites'},
  },

  // /**
  //  * PLANOS DE TESTE
  //  */
  // {
  //   path: '/planosdeteste',
  //   name: 'planosdeteste',
  //   component: () => import(/* webpackChunkName: "planosdeteste" */ '@/pages/planos/PlanosDeTeste.vue'),
  //   meta: {layout: 'principal', permissao: 'planos'}
  // },


  // {
  //   path: '/planosdeteste/adicionar',
  //   name: 'planosdeteste_adicionar',
  //   component: () => import(/* webpackChunkName: "planosdeteste" */ '@/pages/planos/PlanoDeTesteForm.vue'),
  //   meta: {layout: 'principal', permissao: 'planos'}
  // },

  // {
  //   path: '/planosdeteste/:plano_id/editar',
  //   name: 'planosdeteste_editar',
  //   component: () => import(/* webpackChunkName: "planosdeteste" */ '@/pages/planos/PlanoDeTesteForm.vue'),
  //   meta: {editando: true, layout: 'principal', permissao: 'planos'}
  // },

  // {
  //   path: '/planosdeteste/:plano_id',
  //   name: 'planosdeteste_selecionado',
  //   component: () => import(/* webpackChunkName: "releases" */ '@/pages/planos/PlanoSelecionado.vue'),
  //   meta: {layout: 'principal', permissao: 'planos'},
  //   children: [
  //     {
  //       path: 'gerenciar',
  //       name: 'planosdeteste_gerenciar',
  //       component: () => import(/* webpackChunkName: "planosdeteste" */ '@/pages/planos/GerenciarPlanoDeTeste.vue')
  //     },
  //     {
  //       path: 'releases',
  //       name: 'releases_lista',
  //       component: () => import(/* webpackChunkName: "releases_adicionar" */ '@/pages/planos/Releases.vue')
  //     },
  //     {
  //       path: 'reseases/adicionar',
  //       name: 'releases_adicionar',
  //       component: () => import(/* webpackChunkName: "releases_adicionar" */ '@/pages/planos/ReleaseForm.vue')
  //     },
  //     {
  //       path: 'releases/:release_id/editar',
  //       name: 'releases_editar',
  //       component: () => import(/* webpackChunkName: "releases_editar" */ '@/pages/planos/ReleaseForm.vue'),
  //       meta: {
  //         editando: true
  //       }
  //     },
  //     {
  //       path: 'selecionar',
  //       name: 'planosdeteste_selecionar',
  //       component: () => import(/* webpackChunkName: "selecionar" */ '@/pages/planos/SelecionarCasosDeTeste.vue')
  //     },
  //   ]
  // },

  // {
  //   path: '/planosdeteste/semplanos',
  //   name: 'planosdeteste_semplanos',
  //   component: () => import(/* webpackChunkName: "planosdeteste" */ '@/pages/planos/SemPlanos.vue'),
  //   meta: {layout: 'principal', permissao: 'planos'}
  // },

  /**
   * EXECUÇÃO DE TESTES
   */
  {
    path: '/executartestes',
    name: 'executar_visaogeral',
    component: () => import(/* webpackChunkName: "executar" */ '@/pages/planos/ExecutarVisaoGeral.vue'),
    meta: {layout: 'principal', permissao: 'executar_testes'}
  },
  {
    path: '/executartestes/:caso_id',
    name: 'executar_teste',
    component: () => import(/* webpackChunkName: "executar_teste" */ '@/pages/planos/ExecutarTeste.vue'),
    meta: {layout: 'principal', permissao: 'executar_testes'}
  },
 

  /**
   * OUTROS
   */
  {
    path: '/projeto/especificacoes/arvore',
    name: 'projeto_especificacoes_arvore',
    component: () => import(/* webpackChunkName: "projetos" */ '@/pages/requisitos/RequisitosTree.vue'),
    meta: {layout: 'principal'}
  },

  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/About.vue'),
    meta: {layout: 'principal'}
  }
]
