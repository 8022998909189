import config from "@/app/config";

export default new class LocalStorage {

  set(variavel, valor) {
    if (config.STORAGE_ENCRYPT) {
      valor = btoa(JSON.stringify(valor))
    } else {
      valor = JSON.stringify(valor);
    }

    localStorage.setItem(config.APP_PREFIX + variavel, valor);
  }

  get(variavel){
    let valor = localStorage.getItem(config.APP_PREFIX + variavel);

    if (valor) {
      if (config.STORAGE_ENCRYPT) {
        valor = atob(valor);
      }
  
      return JSON.parse(valor);
    }

    return null;

  }
}
