import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import usuarios from './modules/usuarios';
import projetos from './modules/projetos';
import especificacoes from './modules/especificacoes';
import requisitos from './modules/requisitos';
import casosdeteste from './modules/casosdeteste';
import planosdeteste from './modules/planosdeteste';
import releases from './modules/releases';
import execucaodetestes from './modules/execucaodetestes';
import listas from './modules/listas';

export default new Vuex.Store({
  state: {
    titulo_tela: '',
    loading: false,
    leftDrawer: false,
    rightDrawer: false,
    leftDrawerMenu: 'MenuInicial',
    rightDrawerMenu: 'Filtro1',
    navIcon: true,
    configuracoes: {},
    lista_configuracoes: [],
    forms: {
      outlined: false,
      filled: true,
      dense: true
    },
    semprojetos: false
  },

  getters: {
    GLOBAL_LOADING: state => state.loading
  },
  
  mutations: {
    setTitulo(state, titulo) {
      state.titulo_tela = titulo;
    },

    setLeftDrawer(state, exibir) {
      state.leftDrawer = exibir;
    },

    setRightDrawer(state, exibir) {
      state.rightDrawer = exibir;
    },

    setLeftDrawerMenu(state, menu) {
      state.leftDrawerMenu = menu;
    },

    setRightDrawerMenu(state, menu) {
      state.rightDrawerMenu = menu;
    },

    setNavIcon(state, navicon) {
      state.navIcon = navicon;
    },

    setForms(state, config) {
      state.forms.outlined = config.outlined;
      state.forms.filled = config.filled;
    },

    GLOBAL_LOADING(state, status) {
      state.loading = status;
    },
    
    SET_CONFIGURACOES(state, configuracoes) {
      state.configuracoes = configuracoes
    },

    SET_LISTA_CONFIGURACOES(state, configuracoes) {
        state.lista_configuracoes = configuracoes
    },

    ADD_NAVEGACAO(state, item) {
      let index = state.navegacao.findIndex(item_ => item_.text == item.text);
      if (index > -1) {
        state.navegacao.splice(index, state.navegacao.length);
      }
      state.navegacao.push(item);
    },

    SET_NAVEGACAO(state, items) {
      state.navegacao = items;
    },

    LIMPAR_NAVEGACAO(state) {
      state.navegacao = [];
    },

    SET_SEMPROJETOS(state, semprojetos) {
      state.semprojetos = semprojetos;
    }

  },
  actions: {

  },

  modules: {
    usuarios,
    projetos,
    especificacoes,
    requisitos,
    casosdeteste,
    planosdeteste,
    releases,
    execucaodetestes,
    listas
  }
})
