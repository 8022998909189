<template>
  <v-app id="inspire" style="background-color: #F2F2F2;">

    <v-row justify="center" align="center" :no-gutters="$vuetify.breakpoint.smAndDown">
      <v-col cols="12" sm="6" lg="4" xl="3" >
        <v-card :color="$vuetify.breakpoint.smAndDown ? 'transparent' : ''" :flat="$vuetify.breakpoint.smAndDown">
          <v-card-text class="text--primary">
            <v-form v-model="form_valido" ref="form">

              <v-row>
                <v-col cols="12" class="text-center">
                  <h1 class="headline">
                    <span class="font-weight-bold">FAST</span>
                    <span class="font-weight-light"> TEST</span>
                  </h1>
                </v-col>

                <v-col cols="12" class="text-center">
                  <v-avatar color="primary" size="140">
                    <v-icon dark size="80">mdi-rocket</v-icon>
                  </v-avatar>
                </v-col>

                <v-col cols="12" class="text-center">
                  <v-text-field ref="usuario" color="primary" label="Usuário" v-model="usuario.email" placeholder=" "
                            prepend-icon="mdi-account"
                            :rules="regras.email"
                            :error="erros.email.length > 0"
                            :error-messages="erros.email"
                            required autofocus></v-text-field>
                </v-col>
                
                <v-col cols="12" class="text-center">
                  <v-text-field ref="senha" color="primary" label="Senha" v-model="usuario.password" placeholder=" "
                            prepend-icon="mdi-lock"
                            :append-icon="mostrar_senha ? 'mdi-eye-off' : 'mdi-eye'"
                            @click:append="mostrar_senha = !mostrar_senha"
                            :type="mostrar_senha ? 'text' : 'password'"
                            :rules="regras.password"
                            :error="erros.password.length > 0"
                            :error-messages="erros.password"
                            @keypress.enter="login"
                            required></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-btn block color="primary" dark @click="login">Entrar</v-btn>
                </v-col>

                <v-col cols="12" class="text-center pa-0">
                  <b>{{ this.$config.APP_VERSION }}</b>
                </v-col>

              </v-row>

            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <z-aguardar ref="aguardar" color="primary"></z-aguardar>
  </v-app>
</template>

<script>
// import UsuariosService from '../services/usuarios';

import config from "@/app/config";

export default {
  data() {
    return {
      usuario: {
        email: "",
        password: ""
      },
      erros: {
        email: [],
        password: []
      },
      dialog: false,
      form_valido: false,
      regras: {
        email: [v => !!v || 'Informar o nome do usuário'],
        password: [v => !!v || 'Informar a senha do usuário'],
      },
      mostrar_senha: false
    };
  },
  mounted() {
    if (!config.IS_PRODUCTION) {
      this.usuario = {
        email: "",
        password: ""
      };
    }
  },
  methods: {
    login() {
      // this.ativarRegras();

      this.$nextTick(() => {
        if (this.$refs.form.validate()) {
          this.$refs.aguardar.abrir();
          this.zerarErros();
          this.$auth
            .login(this.usuario)
            .then(response => {
              this.limparForm();
              this.$router.push({ path: "/" });
              this.$refs.aguardar.fechar();
            })
            .catch(error => {
              // this.limparForm();
              this.$auth.logout();
              this.$refs.aguardar.fechar();
              // this.erros.password.push("Usuário ou senha inválidos");
              this.exibirErrosServidor();
              this.$refs.senha.focus();
            });
        }
      });
    },

    zerar() {
      this.usuario = {
        email: "",
        password: ""
      };
    },

    zerarErros() {
      this.erros = {
        email: [],
        password: []
      };
    },

    ativarRegras() {
      this.regras = {
        email: [
          v => !!v || "O campo email é obrigatório",
          v =>
            /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
            "O campo e-mail é inválido"
        ],
        password: [
          v => !!v || "O campo senha é obrigatório"
          // (v) => v && v.length >= 4 || 'Mínimo 4 caracteres'
        ]
      };
    },

    processarErrosServidor(erros) {
      if (erros.email) {
        this.erros.email = erros.email;
        console.log("Erro no email: ", this.erros.email);
      }
      if (erros.password) {
        this.erros.password = erros.password;
        console.log("Erro no password");
      }
    },

    exibirErrosServidor(){
      this.erros.password.push("Usuário ou senha inválidos");
      setTimeout(() => {
        this.erros.password = [];
      }, 3000);
    },

    limparForm() {
      this.$refs.form.reset();
      this.regras = {};
    }
  }
};
</script>

<style scoped>
  .topo {
    margin-top: 50px;
  }
</style>
