<template>
  <div>

    <v-container>
      <v-row justify="center" class="text-center">

        <v-col cols="12">
          <v-img :src="require('../assets/logo.png')" class="my-3" contain height="150"></v-img>
        </v-col>

        <v-col cols="12" mb-4>
          <h1 class="display-2 mb-3">
            Bem-vindo ao <span class="font-weight-bold">FAST</span>
            <span class="font-weight-light"> TEST</span>
          </h1>
          <p>
            Uma plataforma experimental para a gerenciamento e execução de casos de teste.
            <!-- <br>please join our online
            <a href="https://community.vuetifyjs.com" target="_blank">Discord Community</a> -->
          </p>
          <!-- <span v-show="projetos.length == 0">Não há projetos criados ou ativos. Ir para <router-link :to="{name: 'projetos'}">Projetos</router-link>.</span> -->

        </v-col>
      </v-row>

      <v-scroll-y-transition mode='out-in'>
        <v-row justify="center" v-show="msg_projetos">
          <v-col cols="12" md="8">
            <v-alert color="error" :text="$root.dark" :outlined="!$root.dark" prominent icon="mdi-rocket" class="text-center">
              <span>Não há projetos criados ou ativos. Clique para ir para <router-link :to="{name: 'projetos'}">Projetos</router-link>.</span>
            </v-alert>
          </v-col>
        </v-row>
      </v-scroll-y-transition>


    </v-container>
  </div>
</template>

<script>
export default {
  data: () => ({
    msg_projetos: false
  }),

  mounted () {
    setTimeout(() => {
      if (this.projetos.length == 0) {
        this.msg_projetos = true;
      }
    }, 500);

    this.$store.commit('setTitulo', 'Dashboard');
    this.$store.commit('LIMPAR_NAVEGACAO');
    this.$store.commit('setLeftDrawerMenu', 'MenuInicial');
  },

  computed: {
    projetos() {
      return this.$store.state.projetos.projetos_ativos;
    },
   
  },

  methods: {
    axios() {
      this.$axios.get('http://localhost:8000/sanctum/csrf-cookie').then(response => {
        console.log(response);
      });
    }
  },
};
</script>
