import { render, staticRenderFns } from "./AppDrawerDireita.vue?vue&type=template&id=88ce6cf0&scoped=true"
import script from "./AppDrawerDireita.vue?vue&type=script&lang=js"
export * from "./AppDrawerDireita.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "88ce6cf0",
  null
  
)

export default component.exports