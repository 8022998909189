export default {

  mounted() {
    if (this.$refs['notificacao']) {

      let notificacao = this.$refs.notificacao;
      let dialogoErro = this.$refs.dialogoErro;

      this.$eventbus.onNotificar(mensagem => {
        notificacao.exibir({
          mensagem: mensagem
        })
      });

      this.$eventbus.onErro(erro => {
        console.log(erro.response);
        if (erro.response && erro.response.status && (erro.response.status == 401 || erro.response.data.error == 'token_not_provided')) {
          notificacao.exibir({
            mensagem: 'Autorização negada. Você será redirecionado para a tela de Login.',
            cor     : 'error',
            timeout : 5000,
            callback: () => {
              this.$auth.logout();
              this.$router.push('/login');
            }
          });
        } else if (erro.response) {
          notificacao.exibir({
            mensagem: erro.mensagem + ' Código: ' + erro.response.status + ' Mensagem: ' + erro.response.data.message,
            cor     : 'error',
            timeout : 5000
          });
          dialogoErro.abrir();
        } else {
          notificacao.exibir({
            mensagem: erro.mensagem,
            cor     : 'error',
            timeout : 5000
          });
        }
      });

      this.$eventbus.onEmit('FECHAR_NOTIFICACAO', () => {
        notificacao.fechar();
      })
  
      this.$eventbus.onEmit('EXIBIR_MENSAGEM_SISTEMA', (mensagem) => {
        notificacao.exibir({
          mensagem: mensagem,
          cor     : 'error',
          timeout : 5000
        });
      })

    }

    this.$eventbus.onEmit('APP_LOGOUT', () => {
      this.$auth.logout();
      this.$router.push('/login');
    })

  },

  beforeDestroy(){
    this.$eventbus.$off('EXIBIR_MENSAGEM');
    this.$eventbus.$off('EXIBIR_MENSAGEM_ERRO');
    this.$eventbus.$off('APP_LOGOUT');
    this.$eventbus.$off('FECHAR_NOTIFICACAO');
  },

}